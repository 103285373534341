import { isActive } from '@/utils/getEnvAndFeatures';
import { FEATURES } from '@/common/constants';

export const CANCEL_REASON_KEYS = {
  REGULAR: 'regular',
  EXTRAORDINARY: 'extraordinary',
  HZB: 'hzb',
  FAILED_EXAM: 'failed_exam',
  FEES: 'fees',
  FAILED_TRIAL: 'failed_trial',
  HEALTH_INSURANCE: 'health_insurance',
  GRADUATION: 'graduation',
  REVOCATION: 'revocation',
  ADMISSION_PROGRAM: 'admission_program',
  PROGRAM_CHANGE_APPLIED: 'program_change_applied',
  PROGRAM_CHANGE_REVOKED: 'program_change_revoked',
  DOCUMENTS_NOT_SUBMITTED: 'documents_not_submitted',
};

export const getCancellationReasons = () => [
  {
    key: CANCEL_REASON_KEYS.REVOCATION,
    name: 'Widerruf des Vertrags',
  },
  {
    key: CANCEL_REASON_KEYS.REGULAR,
    name: 'Kündigung von Seiten des Kunden',
  },
  {
    key: CANCEL_REASON_KEYS.EXTRAORDINARY,
    name: 'Außerordentliche Kündigung',
  },
  {
    key: CANCEL_REASON_KEYS.HZB,
    name: isActive(FEATURES.CLICK_AND_STUDY)
      ? 'Keine Hochschulzugangsberechtigung vorhanden'
      : 'Beglaubigte Hochschulzugangsberechtigung nicht nachgereicht',
  },
  {
    key: CANCEL_REASON_KEYS.DOCUMENTS_NOT_SUBMITTED,
    name: 'Unterlagen nicht nachgereicht',
  },
  {
    key: CANCEL_REASON_KEYS.FAILED_EXAM,
    name: 'Endgültig nicht bestandene Prüfung',
  },
  {
    key: CANCEL_REASON_KEYS.FEES,
    name: 'Gebühren wurden nicht bezahlt',
  },
  {
    key: CANCEL_REASON_KEYS.FAILED_TRIAL,
    name: 'Probestudium nicht bestanden',
  },
  {
    key: CANCEL_REASON_KEYS.HEALTH_INSURANCE,
    name: 'Ungültiger Krankenversicherungsstatus',
  },
  {
    key: CANCEL_REASON_KEYS.GRADUATION,
    name: 'Graduierung',
  },
  {
    key: CANCEL_REASON_KEYS.ADMISSION_PROGRAM,
    name: 'Zulassungsprogramm(e) nicht bestanden',
  },
  {
    key: CANCEL_REASON_KEYS.PROGRAM_CHANGE_APPLIED,
    name: 'Studiengangwechsel durchgeführt',
  },
  {
    key: CANCEL_REASON_KEYS.PROGRAM_CHANGE_REVOKED,
    name: 'Studiengangwechsel widerrufen',
  },
];
