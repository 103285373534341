<template>
  <process-modal-form
    :id="id"
    :invalid="invalid || !interruptionIsValid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitInterruption"
  >
    <template #form-content>
      <div class="flex flex-col space-y-6">
        <div>
          <validation-provider
            v-slot="{ errors }"
            name="Grund für die Beurlaubung"
            rules="required"
            immediate
          >
            <e-multiselect
              id="interruption-reason"
              v-model="interruptionReason"
              searchable
              :options="availableInterruptionReasons"
              option-label="label"
              track-by="key"
              label="Grund für die Beurlaubung"
              :errors="errors"
              required
              mapped
            />
          </validation-provider>
        </div>

        <div>
          <validation-provider
            v-slot="{ errors }"
            name="Zeitraum"
            :rules="{ required: isInterruptionRequired }"
            immediate
          >
            <e-multiselect
              id="interruption-length"
              v-model="interruptionLength"
              searchable
              :disabled="isInterruptionRequired === false"
              :options="availableInterruptionLengths"
              option-label="label"
              track-by="key"
              label="Zeitraum"
              :errors="errors"
              required
              mapped
            />
          </validation-provider>
        </div>

        <div class="flex space-x-4">
          <validation-provider
            v-slot="{ errors }"
            name="Start Beurlaubung"
            :rules="{
              required: isInterruptionLengthRequired,
              date_format: true,
              after: { date: interruptionStartDateMin, inclusive: true },
              before: { date: interruptionStartDateMax, inclusive: true },
            }"
            slim
            immediate
          >
            <e-text-field
              id="interruption-interruptionStartDate"
              v-model="interruptionStartDate"
              type="date"
              label="Start Beurlaubung"
              :disabled="isInterruptionLengthRequired === false"
              wide
              :errors="errors"
              required
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            name="Ende Beurlaubung"
            :rules="{ required: true, date_format: true }"
            slim
            immediate
          >
            <e-text-field
              id="interruption-interruptionEndDate"
              v-model="interruptionEndDate"
              type="date"
              label="Ende Beurlaubung"
              wide
              :errors="errors"
              required
              disabled
            />
          </validation-provider>
        </div>
        <p v-if="admissionWarning" class="text-status-orange">
          <e-icon icon="mdi-alert-outline" class="mr-1" size="xs" />
          {{ admissionWarning }}
        </p>
        <p v-if="tmcError" class="text-status-red">
          <e-icon icon="mdi-alert-outline" class="mr-1" size="xs" />
          {{ tmcError }}
        </p>
      </div>
    </template>
    <template #submit-button-label>Hinzufügen</template>
    <template #additional-content>
      <p class="text-status-orange text-sm">
        Beurlaubung darf nur zum
        <span class="font-bold">persönlichen Monatsstart</span> beginnen.<br />
        <span class="font-bold">Alle Buchungen</span> werden auf den Status “Beurlaubt” gesetzt.
      </p>
    </template>
  </process-modal-form>
</template>

<script>
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { ETextField, EMultiselect, EIcon } from '@careerpartner/nitro';
import { ValidationProvider } from 'vee-validate';
import { INTERRUPTION_LENGTH, INTERRUPTION_REASONS } from '@/common/selectLists/interruptions';
import dayjs from 'dayjs';
import { API_ENDPOINTS, DATE_FORMAT } from '@/common/constants';
import { Getters as BookingGetters } from '@/store/modules/booking/types';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';
import { Actions as AppActions } from '@/store/modules/app/types';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';

export default {
  name: 'InterruptionProcessModal',
  components: {
    ETextField,
    EMultiselect,
    ValidationProvider,
    ProcessModalForm,
    EIcon,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['set-interruption'],
  data() {
    return {
      error: '',
      admissionWarning: '',
      tmcError: '',
      interruptionLength: '',
      interruptionReason: '',
      interruptionEndDate: '',
      interruptionStartDate: '',
      interruptionStartDateMin: '',
      interruptionStartDateMax: '',
      waitingForResponse: false,
      interruptionIsValid: true,
    };
  },
  computed: {
    availableInterruptionReasons() {
      return INTERRUPTION_REASONS;
    },
    availableInterruptionLengths() {
      return INTERRUPTION_LENGTH;
    },
    isInterruptionRequired() {
      return this.interruptionReason?.length !== 0;
    },
    isInterruptionLengthRequired() {
      return this.interruptionLength?.length !== 0;
    },
    profileId() {
      return this.$store.getters[`profile/${ProfileGetters.PROFILE_ID}`];
    },
  },
  watch: {
    async interruptionStartDate() {
      this.calculateInterruptionEndDate();
      await this.validateAddInterruption();
    },
    interruptionLength() {
      this.calculateInterruptionEndDate();
    },
  },
  async beforeMount() {
    this.setDates();
    await this.validateAddInterruption();
  },
  methods: {
    async validateAddInterruption() {
      const requestUrl = API_ENDPOINTS.VALIDATE_ADD_INTERRUPTION.replace(
        ':profileId',
        this.profileId
      );

      const {
        data: { admission, tmc },
      } = await ApiServiceWrapper.get(requestUrl, {
        params: {
          interruptionStartDate: this.interruptionStartDate,
          interruptionEndDate: this.interruptionEndDate,
        },
      });
      this.admissionWarning = admission
        ? ''
        : 'Es liegt eine aktive Zulassung vor. Bitte beachte dies beim Hinzufügen der Beurlaubung.';

      if (tmc) {
        this.interruptionIsValid = false;
        this.tmcError = `In diesem Zeitraum findet ein Zeitmodellwechsel statt (${tmc}). Eine Beurlaubung kann nicht parallel hinterlegt werden.`;
      } else {
        this.interruptionIsValid = true;
        this.tmcError = '';
      }
    },
    setDates() {
      this.interruptionStartDate = dayjs().format(DATE_FORMAT);
      const earliestBookingDate =
        this.$store.getters[`booking/${BookingGetters.GET_EARLIEST_BOOKING_START_DATE}`];

      this.interruptionStartDateMin = dayjs().format(DATE_FORMAT);
      if (dayjs(earliestBookingDate).isAfter(dayjs())) {
        this.interruptionStartDateMin = earliestBookingDate;
      }

      this.interruptionStartDateMax =
        this.$store.getters[`booking/${BookingGetters.GET_LATEST_BOOKING_END_DATE}`];
      this.interruptionEndDate = dayjs().add(6, 'month').format(DATE_FORMAT);
      this.newStudyEndDate = dayjs().format(DATE_FORMAT);
    },
    calculateInterruptionEndDate() {
      this.interruptionEndDate = dayjs(this.interruptionStartDate)
        .add(Number(this.interruptionLength), 'month')
        .format(DATE_FORMAT);
    },
    async submitInterruption() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.INTERRUPTION.replace(':profileId', this.profileId);

      const payload = {
        period: Number(this.interruptionLength),
        startDate: this.interruptionStartDate,
        reason: this.interruptionReason,
      };

      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Beurlaubung hinzufügen war erfolgreich.',
        });

        this.$emit('set-interruption');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
