<template>
  <e-chip v-if="value" default-style>
    {{ text }}
  </e-chip>
</template>

<script>
import { ACADEMIC_CATEGORY_TYPE } from '@/common/constants';
import { EChip } from '@careerpartner/nitro';

export default {
  components: { EChip },
  props: { value: { type: String, default: undefined } },
  computed: {
    text() {
      return ACADEMIC_CATEGORY_TYPE[this.value];
    },
  },
};
</script>
