export const Mutations = {
  SET_TEMPLATES: 'setTemplates',
  SET_TAGS: 'setTags',
  SET_BINDINGS: 'setBindings',
  SET_VARIABLES: 'setVariables',
  SET_QUERIES: 'setQueries',
  RESET_DATA: 'resetData',
};

export const Actions = {
  FETCH_TAGS: 'fetchTags',
  FETCH_TEMPLATES: 'fetchTemplates',
  FETCH_BINDINGS: 'fetchBindings',
  GENERATE_DOCUMENT: 'generateDocument',
};

export const Getters = {
  TAGS: 'tags',
  TAG_IDS: 'tagIds',
  TEMPLATES: 'templates',
  SELECTED_TEMPLATE: 'selectedTemplate',
  BINDINGS: 'bindings',
};
