export const campuses = [
  { externalId: '1', name: 'CS-Bad Honnef', abbreviation: 'BH' },
  { externalId: '2', name: 'CS-Bad Reichenhall', abbreviation: 'BR' },
  { externalId: '3', name: 'Distance Learning', abbreviation: 'FS' },
  { externalId: '4', name: 'CS-Berlin', abbreviation: 'BER' },
  { externalId: '5', name: 'NEC M\u00fcnchen', abbreviation: 'NECMUC' },
  { externalId: '6', name: 'Upskilling', abbreviation: 'UPS' },
  { externalId: '10000001', name: 'DS-Erfurt', abbreviation: 'DS-EF' },
  { externalId: '10000002', name: 'DS-M\u00fcnchen', abbreviation: 'DS-MUC' },
  {
    externalId: '10000003',
    name: 'DS-D\u00fcsseldorf',
    abbreviation: 'DS-DUS',
  },
  { externalId: '10000004', name: 'DS-Bad Reichenhall', abbreviation: 'DS-BR' },
  { externalId: '10000005', name: 'DS-Bad Honnef', abbreviation: 'DS-BH' },
  { externalId: '10000006', name: 'DS-Berlin', abbreviation: 'DS-BER' },
  {
    externalId: '10000007',
    name: 'DS-Frankfurt am Main',
    abbreviation: 'DS-FFM',
  },
  { externalId: '10000008', name: 'DS-Hamburg', abbreviation: 'DS-HH' },
  { externalId: '10000009', name: 'DS-Bremen', abbreviation: 'DS-HB' },
  { externalId: '10000010', name: 'DS-Hannover', abbreviation: 'DS-HAN' },
  { externalId: '10000011', name: 'DS-Dortmund', abbreviation: 'DS-DO' },
  { externalId: '10000012', name: 'DS-N\u00fcrnberg', abbreviation: 'DS-NB' },
  { externalId: '10000013', name: 'DS-Wien', abbreviation: 'DS-WIEN' },
  { externalId: '10000014', name: 'DS-Mannheim', abbreviation: 'DS-MAN' },
  { externalId: '10000015', name: 'DS-Peine', abbreviation: 'DS-PE' },
  { externalId: '10000017', name: 'DS-Essen', abbreviation: 'DS-ES' },
  { externalId: '10000018', name: 'DS-K\u00f6ln', abbreviation: 'DS-KOL' },
  { externalId: '10000019', name: 'DS-Stuttgart', abbreviation: 'DS-STU' },
  { externalId: '10000020', name: 'DS-L\u00fcbeck', abbreviation: 'DS-HL' },
  { externalId: '10000021', name: 'DS-Mainz', abbreviation: 'DS-MZ' },
  { externalId: '10000022', name: 'DS-Leipzig', abbreviation: 'DS-LE' },
  { externalId: '10000023', name: 'DS-Augsburg', abbreviation: 'DS-AUG' },
  { externalId: '10000025', name: 'DS-M\u00fcnster', abbreviation: 'DS-MS' },
  {
    externalId: '10000026',
    name: 'DS-Virtueller Campus',
    abbreviation: 'DS-VC',
  },
  { externalId: '10000036', name: 'DS-Kassel', abbreviation: 'KAS' },
  { externalId: '10000037', name: 'DS-Ravensburg', abbreviation: 'RAV' },
  { externalId: '10000038', name: 'DS-Saarbr\u00fccken', abbreviation: 'SCN' },
  { externalId: '10000039', name: 'DS-Aachen', abbreviation: 'AAH' },
  { externalId: '10000040', name: 'DS-Wuppertal', abbreviation: 'WUP' },
  {
    externalId: '10000041',
    name: 'DS-M\u00f6nchengladbach',
    abbreviation: 'MGL',
  },
  { externalId: '10000042', name: 'DS-Bochum', abbreviation: 'BOM' },
  { externalId: '10000043', name: 'DS-Rostock', abbreviation: 'RSK' },
  { externalId: '10000044', name: 'DS-Regensburg', abbreviation: 'REG' },
  { externalId: '10000045', name: 'DS-Potsdam', abbreviation: 'POT' },
];
