import { setPublicPath } from 'systemjs-webpack-interop';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import '@careerpartner/nitro/dist/styles.css';
import '@/assets/tailwind.css';
import { getEnvAndFeatures } from './utils/getEnvAndFeatures'
import FeaturePlugin from '@/plugins/feature';

setPublicPath(process.env.APPLET_NAME);

Vue.config.productionTip = false;
Vue.config.errorHandler = (error) => {
  throw error;
};
Vue.use(VueMeta);
Vue.use(FeaturePlugin);

const CONTAINER_SELECTOR = '#app-placeholder'

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App);
    },
    router,
    store,
    el: CONTAINER_SELECTOR,
  },
});

export const bootstrap = vueLifecycles.bootstrap;

export const mount = async (props) => {
  await getEnvAndFeatures();
  return vueLifecycles.mount(props);
};

export const unmount = vueLifecycles.unmount;

export const devtools = {
  overlays: {
    selectors: [CONTAINER_SELECTOR],
  },
};
