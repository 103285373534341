import { EDIT_CONTRACT_EVENTS } from '@/common/events/editContractEvents';
import { FEATURES, ENROLMENT_STATUS_STRINGS } from '@/common/constants';
import dayjs from 'dayjs';
import { isActive } from '../getEnvAndFeatures';

const editContractMenuItems = [
  {
    id: EDIT_CONTRACT_EVENTS.ON_CHANGE_STUDY_START,
    text: 'Studienstart verschieben',
    disabled: true,
    event: 'menu-button-clicked',
    showBottomSeparator: true,
  },
  {
    id: EDIT_CONTRACT_EVENTS.ON_CHANGE_REVOCATION_DATE,
    text: 'Widerrufsfrist ändern',
    disabled: true,
    event: 'menu-button-clicked',
    showBottomSeparator: false,
  },
];

export default function (studyStartDate, status) {
  const today = dayjs();
  const availableItems = editContractMenuItems;

  const enableItem = (key) => {
    const index = availableItems.findIndex((x) => x.id === key);

    const item = availableItems.at(index);
    item.disabled = false;

    availableItems[index] = item;
  };

  const disableItem = (key) => {
    const index = availableItems.findIndex((x) => x.id === key);

    const item = availableItems.at(index);
    item.disabled = true;

    availableItems[index] = item;
  };

  disableItem(EDIT_CONTRACT_EVENTS.ON_CHANGE_STUDY_START);
  disableItem(EDIT_CONTRACT_EVENTS.ON_CHANGE_REVOCATION_DATE);

  if (status !== ENROLMENT_STATUS_STRINGS.STUDYING && today.isBefore(studyStartDate)) {
    enableItem(EDIT_CONTRACT_EVENTS.ON_CHANGE_STUDY_START);
  }

  const revocationDateMax = dayjs(studyStartDate).add(3, 'months');
  if (isActive(FEATURES.CHANGE_REVOCATION_DEADLINE) && today.isBefore(revocationDateMax)) {
    enableItem(EDIT_CONTRACT_EVENTS.ON_CHANGE_REVOCATION_DATE);
  }

  return availableItems;
}
