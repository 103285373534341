export const REVOCATION_CATEGORIES = [
  {
    key: 'private_reasons',
    name: 'private Gründe',
    descriptions: [
      { name: 'familiäre Umstände' },
      { name: 'gesundheitliche Gründe' },
      { name: 'unterschätzter Zeitaufwand' },
      { name: 'Zeitmangel' },
      { name: 'Motivationsprobleme' },
      { name: 'berufliche Umorientierung' },
      { name: 'Arbeitslosigkeit' },
      { name: 'finanzielle Gründe' },
    ],
  },
  {
    key: 'unsatisfied',
    name: 'Unzufriedenheit mit der IU',
    descriptions: [
      { name: 'unzufrieden mit den Lehrformaten' },
      { name: 'unzufrieden mit den Lehrmaterialien' },
      { name: 'unzufrieden mit dem Service der IU' },
      { name: 'unzufrieden mit dem Onboarding' },
    ],
  },
  {
    key: 'unsuitable_course',
    name: 'Studium nicht passend',
    descriptions: [
      { name: 'falsche Studienform' },
      { name: 'falscher Studiengang' },
      { name: 'Wechsel zu anderem Fernstudium-Anbieter' },
      { name: 'Wechsel zu anderem Dualstudium-Anbieter' },
      { name: 'Wechsel zu Präsenzuniversität' },
    ],
  },
  {
    key: 'other',
    name: 'Sonstiges',
  },
];
