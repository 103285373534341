import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper } from './ApiServiceWrapper';
import store from '@/store';
import { Actions as AppActions } from '@/store/modules/app/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
export const calculateContractEnd = async ({ cancellationDate, reason, bookingId }) => {
  const payload = {
    cancellationDate,
    reason,
  };

  const requestUrl = API_ENDPOINTS.CALCULATE_CONTRACT_END.replace(':bookingId', bookingId);

  try {
    const { data: result } = await ApiServiceWrapper.post(requestUrl, payload);
    const formattedContractEnd = dayjs.utc(result).format('YYYY-MM-DD');
    return formattedContractEnd;
  } catch (error) {
    store.dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
  }
};
