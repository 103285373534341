<template>
  <Loader v-if="waitingForResponse" />
  <process-modal-form
    v-else
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitRecognition"
  >
    <template #form-content>
      <div>
        <validation-provider
          v-slot="{ errors }"
          name="Erreichte ECTS Anzahl"
          slim
          immediate
          :rules="{
            required: true,
            numeric: true,
            min_value: {
              value: ects,
              min: 1,
            },
            max_value: {
              value: ects,
              max: maxEctsAmount,
            },
          }"
        >
          <e-text-field
            id="ects-amount"
            v-model.number="ects"
            label="Erreichte ECTS Anzahl"
            wide
            :errors="errors"
            required
            type="number"
          />
        </validation-provider>
      </div>
      <div>
        <validation-provider v-slot="{ errors }" rules="required">
          <e-multiselect
            id="recognition-type"
            v-model="recognitionType"
            searchable
            :options="recognitionTypes"
            option-label="name"
            track-by="id"
            label="Art der Anerkennung"
            :errors="errors"
            required
            mapped
          />
        </validation-provider>
      </div>
      <div>
        <validation-provider v-slot="{ errors }" rules="required">
          <e-multiselect
            id="care-type"
            v-model="recognitionCareType"
            searchable
            :options="recognitionCareTypes"
            option-label="name"
            track-by="id"
            label=" Art der ECTS Anerkennung"
            :errors="errors"
            required
            mapped
          />
        </validation-provider>

        <e-infobanner
          v-if="recognitionCareType === 'internal' || recognitionCareType === 'external'"
          variant="info"
          style="margin-top: 10px"
        >
          <template #message>
            Hierdurch wird in Care ein Schlagwort gesetzt für die Berechnung der Kostenreduktion.
          </template>
        </e-infobanner>
      </div>
    </template>

    <template #submit-button-label>Anerkennen</template>
  </process-modal-form>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import { EMultiselect, ETextField, EInfobanner } from '@careerpartner/nitro';
import Loader from '@/components/shared/Loader';

import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import { Actions as AppActions } from '@/store/modules/app/types';

export default {
  name: 'AddRecognitionModal',
  components: {
    ProcessModalForm,
    ValidationProvider,
    EMultiselect,
    ETextField,
    EInfobanner,
    Loader,
  },

  props: {
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  emits: ['added-recognition-successfully'],
  data() {
    return {
      error: '',
      waitingForResponse: false,
      recognitionType: '',
      recognitionCareType: '',
      ects: 1,
      recognitionTypes: [
        {
          name: 'Studienleistung',
          id: 'study',
        },
        {
          name: 'Berufserfahrung',
          id: 'work',
        },
        {
          name: 'Erfahrung aus dem Ausland',
          id: 'abroad',
        },
      ],
      recognitionCareTypes: [
        {
          name: 'Intern',
          id: 'internal',
        },
        {
          name: 'Extern',
          id: 'external',
        },
        {
          name: 'Nachträglich',
          id: 'retro',
        },
      ],
    };
  },
  computed: {
    maxEctsAmount() {
      let recognisedSemesters = 0;

      this.booking.history.forEach((history) => {
        recognisedSemesters += this.sumRecognisedSemesters(history.recognition);
      });

      return 180 - recognisedSemesters * 30;
    },
  },

  methods: {
    async submitRecognition() {
      this.waitingForResponse = true;
      const requestUrl = API_ENDPOINTS.RECOGNIZE_SEMESTERS.replace(
        ':bookingId',
        this.$route.params.bookingId
      );

      const payload = {
        type: this.recognitionType,
        ects: this.ects,
        careType: this.recognitionCareType,
      };

      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Anerkennung wurde erfolgreich hinzugefügt.',
        });

        this.$emit('added-recognition-successfully');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
    sumRecognisedSemesters(recognition) {
      return recognition.work + recognition.study + recognition.foreign;
    },
  },
};
</script>
