import dayjs from 'dayjs';

export default {
  methods: {
    formatDate(date) {
      if (!date) return '-';
      const template = 'DD.MM.YYYY';
      return dayjs(date).format(template);
    },
  },
};
