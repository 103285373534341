import Vue from 'vue';
import Router from 'vue-router';
import { Routes } from '@epos/core-applets';
import BookingDetail from '@/views/BookingDetail/Index';
import BookingMatriculate from '@/views/BookingMatriculate';
import Bookings from '@/views/Bookings';

Vue.use(Router);

const routes = [
  {
    path: Routes.CUSTOMER_SERVICES.BOOKING,
    component: Bookings,
    name: 'bookingOverview',
    meta: {
      title: 'Buchungen - Kundenverwaltung',
      headline: 'Buchungen',
    },
  },
  {
    //  BOOKING_DETAIL: `/services/customers/:id/booking/:bookingId`,
    path: Routes.CUSTOMER_SERVICES.BOOKING_DETAIL,
    component: BookingDetail,
    name: 'bookingDetail',
    meta: {
      title: 'Buchungsansicht - Kundenverwaltung',
      headline: 'Buchungsdetail',
    },
  },
  {
    path: `/services/customers/:id/booking/:bookingId/matriculate`,
    component: BookingMatriculate,
    name: 'bookingMatriculate',
    meta: {
      title: 'Buchungsansicht - Kundenverwaltung',
      headline: 'Kundenprofil',
    },
  },
];

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  next();
});

export default router;
