<template>
  <e-infobanner
    v-if="isProfileOwnedByCARE"
    variant="error"
    style="top: 50px"
    class="sticky z-20"
  >
    <template #message>
      Achtung CARE Profil! Bitte in CARE bearbeiten!
    </template>
    <template #actions>
      <e-button variant="blank" @click="linkToCare()">
        <span class="text-white hover:underline uppercase font-bold">
          Zum CARE Profil
        </span>
      </e-button>
    </template>
  </e-infobanner>
</template>

<script>
import { EButton, EInfobanner } from '@careerpartner/nitro';
import {
  Actions as ProfileActions,
  Getters as ProfileGetters,
} from '@/store/modules/profile/types';

export default {
  components: {
    EInfobanner,
    EButton,
  },
  computed: {
    isProfileOwnedByCARE() {
      return this.$store.getters[
        `profile/${ProfileGetters.IS_PROFILE_OWNED_BY_CARE}`
      ];
    },
  },
  methods: {
    linkToCare() {
      this.$store.dispatch(`profile/${ProfileActions.OPEN_CARE_PROFILE}`);
    },
  },
};
</script>
