import { extend } from 'vee-validate';
import dayjs from 'dayjs';
import { validationErrorMessages } from '@/common/validationErrorMessages';
import { numeric, required } from 'vee-validate/dist/rules';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

extend('max', {
  validate(value, args) {
    return (
      value &&
      (value.length <= args.length ||
        validationErrorMessages.length(args.length))
    );
  },
  params: ['length'],
});

extend('required', {
  ...required,
  message: validationErrorMessages.required,
});

extend('numeric', {
  ...numeric,
  message: validationErrorMessages.numeric,
});

extend('max_value', {
  params: ['max'],
  validate: (value, { max }) => {
    return value <= max;
  },
  message: validationErrorMessages.maxTerms,
});

extend('min_value', {
  params: ['min'],
  validate: (value, { min }) => {
    return value >= min;
  },
  message: validationErrorMessages.minTerms,
});

extend('date_format', (value) => {
  const date = dayjs(value);
  return date.isValid();
});

const checkCompareDate = (date) => {
  const compareDate = dayjs(date);
  if (!compareDate.isValid()) {
    throw new Error("compare date is not valid for rule 'before'" + date);
  }
  return compareDate;
};

extend('before', {
  params: ['date', 'inclusive'],
  validate: (value, { date, inclusive }) => {
    const compareDate = checkCompareDate(date);

    if (!value) {
      return true;
    }

    const valueDate = dayjs(value);
    const validValue = valueDate.isValid();
    const isBefore = inclusive
      ? valueDate.isSameOrBefore(compareDate, 'date')
      : valueDate.isBefore(compareDate, 'date');
    if (validValue && isBefore) {
      return true;
    }
    return validationErrorMessages.before(date, inclusive);
  },
});
extend('after', {
  params: ['date', 'inclusive'],
  validate: (value, { date, inclusive = false }) => {
    const compareDate = checkCompareDate(date);

    if (!value) {
      return true;
    }

    const valueDate = dayjs(value);
    const validValue = valueDate.isValid();
    const isAfter = inclusive
      ? valueDate.isSameOrAfter(compareDate, 'date')
      : valueDate.isAfter(compareDate, 'date');
    if (validValue && isAfter) {
      return true;
    }
    return validationErrorMessages.after(date, inclusive);
  },
});
