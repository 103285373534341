import { authMiddleware } from '@epos/core-applets';
import { Buffer } from 'buffer';

export const Getters = {
  ACADEMY_ID: 'academyId',
};

export const Actions = {
  DECODE_TOKEN: 'decodeToken',
  SET_CURRENT_USER: 'setCurrentUser',
};

const KEY_ROLES = 'platform_roles';
const KEY_ACADEMY_ID = 'academy_id';
const KEY_IAM_ID = 'id';

export default {
  namespaced: true,

  state() {
    return {
      family_name: '',
      given_name: '',
      email: '',
      roles: [],
      academyId: null,
      iamId: null,
    };
  },
  actions: {
    [Actions.SET_CURRENT_USER]({ commit }) {
      commit('saveCurrentUser', authMiddleware.user);
    },
    async [Actions.DECODE_TOKEN]({ commit }) {
      const token = await authMiddleware.getToken();
      const parts = token.split('.');
      const payload = JSON.parse(Buffer.from(parts[1], 'base64').toString());
      commit('saveTokenInfos', payload);
    },
  },
  mutations: {
    saveCurrentUser(state, user) {
      state.family_name = user.family_name;
      state.given_name = user.given_name;
      state.email = user.email;
      state.roles = user[KEY_ROLES];
    },
    saveTokenInfos(state, decodedToken) {
      state.academyId = decodedToken[KEY_ACADEMY_ID];
      state.iamId = decodedToken[KEY_IAM_ID];
    },
  },
  getters: {
    [Getters.ACADEMY_ID](state) {
      return state.academyId;
    },
  },
};
