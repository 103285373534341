var render = function render(){var _vm=this,_c=_vm._self._c;return _c('process-modal-form',{attrs:{"id":_vm.id,"invalid":_vm.invalid,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitGraduation},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('div',{staticClass:"flex space-x-4"},[_c('validation-provider',{attrs:{"name":"Datum der Kündigung","rules":{
          required: true,
          date_format: true,
          after: { date: _vm.graduationDateMin, inclusive: true },
          before: { date: _vm.graduationDateMax, inclusive: true },
        },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"booking-graduation-graduationDate","type":"date","label":"Datum der Kündigung","wide":"","errors":errors,"required":""},model:{value:(_vm.graduationDate),callback:function ($$v) {_vm.graduationDate=$$v},expression:"graduationDate"}})]}}])}),_c('validation-provider',{attrs:{"name":"Studienende","rules":{
          required: true,
          date_format: true,
          after: { date: _vm.exmatriculationDateMin, inclusive: true },
          before: { date: _vm.exmatriculationDateMax, inclusive: true },
        },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"booking-graduation-exmatriculationDate","type":"date","label":"Studienende","wide":"","errors":errors,"required":""},model:{value:(_vm.exmatriculationDate),callback:function ($$v) {_vm.exmatriculationDate=$$v},expression:"exmatriculationDate"}})]}}])})],1),_c('validation-provider',{attrs:{"name":"Datum der letzten Leistung","rules":{
        required: true,
        date_format: true,
        after: { date: _vm.graduationExamDateMin, inclusive: true },
        before: { date: _vm.graduationExamDateMax, inclusive: true },
      },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"booking-graduation-graduationExamDate","type":"date","label":"Datum der letzten Leistung","errors":errors,"required":"","disabled":_vm.graduationExamDateDisabled},model:{value:(_vm.graduationExamDate),callback:function ($$v) {_vm.graduationExamDate=$$v},expression:"graduationExamDate"}})]}}])})]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v(" Buchung per Graduierung beenden ")]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }