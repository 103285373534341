<template>
  <process-modal-form
    :id="id"
    data-test="change-admission-status-modal"
    :show-notice="false"
    :invalid="!canChangeAdmissionStatus"
    @submit="submitForm"
  >
    <template #form-content>
      <template v-if="canChangeAdmissionStatus">
        <p>Bist du sicher, dass du fortfahren möchtest?</p>
        <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
      </template>
      <template v-else>
        <p>
          Der Status der Zulassung kann nicht geändert werden, da die Dokument nicht eingereicht
          sind.
        </p>
      </template>
    </template>

    <template #submit-button-label>BESTÄTIGEN</template>
  </process-modal-form>
</template>

<script>
import ProcessModalForm from '../ProcessModals/shared/ProcessModalForm.vue';

export default {
  name: 'AdmissionsChangeConfirmModal',
  components: {
    ProcessModalForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    canChangeAdmissionStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['confirm-change-admission-status'],
  methods: {
    async submitForm() {
      this.$emit('confirm-change-admission-status');
    },
  },
};
</script>
