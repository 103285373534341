<template>
  <process-modal-form
    :id="id"
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitGraduation"
  >
    <template #form-content>
      <!-- Dates -->
      <div class="flex space-x-4">
        <validation-provider
          v-slot="{ errors }"
          name="Datum der Kündigung"
          :rules="{
            required: true,
            date_format: true,
            after: { date: graduationDateMin, inclusive: true },
            before: { date: graduationDateMax, inclusive: true },
          }"
          slim
          immediate
        >
          <e-text-field
            id="booking-graduation-graduationDate"
            v-model="graduationDate"
            type="date"
            label="Datum der Kündigung"
            wide
            :errors="errors"
            required
          />
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          name="Studienende"
          :rules="{
            required: true,
            date_format: true,
            after: { date: exmatriculationDateMin, inclusive: true },
            before: { date: exmatriculationDateMax, inclusive: true },
          }"
          slim
          immediate
        >
          <e-text-field
            id="booking-graduation-exmatriculationDate"
            v-model="exmatriculationDate"
            type="date"
            label="Studienende"
            wide
            :errors="errors"
            required
          />
        </validation-provider>
      </div>

      <validation-provider
        v-slot="{ errors }"
        name="Datum der letzten Leistung"
        :rules="{
          required: true,
          date_format: true,
          after: { date: graduationExamDateMin, inclusive: true },
          before: { date: graduationExamDateMax, inclusive: true },
        }"
        slim
        immediate
      >
        <e-text-field
          id="booking-graduation-graduationExamDate"
          v-model="graduationExamDate"
          type="date"
          label="Datum der letzten Leistung"
          :errors="errors"
          required
          :disabled="graduationExamDateDisabled"
        />
      </validation-provider>
    </template>

    <template #submit-button-label> Buchung per Graduierung beenden </template>
  </process-modal-form>
</template>

<script>
import { ETextField } from '@careerpartner/nitro';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { API_ENDPOINTS, DATE_FORMAT } from '@/common/constants';
import dayjs from 'dayjs';
import { Actions as AppActions } from '@/store/modules/app/types';
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';

extend('required', {
  ...required,
  message: 'Dieses Feld muss ausgefüllt sein.',
});

export default {
  name: 'GraduationModal',
  components: {
    ProcessModalForm,
    ETextField,
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  emits: ['graduation-successful', 'input'],
  data() {
    return {
      error: '',
      exmatriculationDate: null,
      exmatriculationDateMax: null,
      exmatriculationDateMin: null,
      graduationDate: null,
      graduationDateMax: null,
      graduationDateMin: null,
      graduationExamDate: null,
      graduationExamDateDisabled: false,
      graduationExamDateMax: null,
      graduationExamDateMin: null,
      waitingForResponse: false,
    };
  },
  watch: {
    graduationExamDate(value) {
      this.exmatriculationDateMin = value;
    },
  },
  beforeMount() {
    this.setDates();
  },
  beforeUnmount() {
    this.graduationDate = null;
    this.exmatriculationDate = null;
    this.graduationExamDate = null;
  },
  methods: {
    setDates() {
      let lastSemesterEndDate = this.booking.endDate;
      const lastExamDate = this.booking.graduationExamDate;

      if (this.booking.history.length) {
        lastSemesterEndDate = this.booking.history
          .map((semester) => semester.endDate)
          .sort((a, b) => dayjs(a).isAfter(dayjs(b)))
          .pop();
      }

      this.graduationDate = lastSemesterEndDate;
      this.graduationDateMin = this.booking.startDate;
      this.graduationDateMax = this.booking.endDate;

      this.exmatriculationDate = lastSemesterEndDate;
      this.exmatriculationDateMin = lastExamDate || this.booking.startDate;
      this.exmatriculationDateMax = lastSemesterEndDate;

      if (lastExamDate) {
        this.graduationExamDate = lastExamDate;
        this.graduationExamDateDisabled = true;
      } else {
        this.graduationExamDate = dayjs().format(DATE_FORMAT);
        this.graduationExamDateMin = this.booking.startDate;
        this.graduationExamDateMax = lastSemesterEndDate;
      }
    },
    async submitGraduation() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.GRADUATION.replace(
        ':bookingId',
        this.$route.params.bookingId
      );

      const payload = {
        graduationDate: this.graduationDate,
        exmatriculationDate: this.exmatriculationDate,
        graduationExamDate: this.graduationExamDate,
      };

      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Buchung erfolgreich graduiert.',
        });

        this.$emit('graduation-successful');
        this.$emit('input', false);
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
