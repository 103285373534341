import { ENROLMENT_STATUS_STRINGS } from '@/common/constants';
import { Getters } from './types';
import { NEXT_MATRICULATION_STATE } from '@/common/selectLists/matriculation';
import dayjs from 'dayjs';

export default {
  [Getters.GET_BOOKINGS](state) {
    return state.bookings;
  },
  [Getters.GET_CURRENT_BOOKING](state) {
    return state.currentBooking;
  },
  [Getters.GET_CURRENT_BOOKING_ID](state) {
    return state.currentBooking?.id;
  },
  [Getters.HAS_INTERRUPTED_BOOKINGS](state) {
    return state.bookings.some(
      (booking) => booking.status.name === ENROLMENT_STATUS_STRINGS.INTERRUPTED,
    );
  },
  [Getters.HAS_FAILED_ADMISSION_PROGRAM](state) {
    return state.currentBooking?.admissionCourses.some(
      (booking) => booking.status === 'failed',
    );
  },
  [Getters.IS_ACADEMIC_PROGRAM](state) {
    return state.currentBooking?.category === 'academic';
  },
  [Getters.GET_CURRENT_BOOKING_STATUS](state) {
    return state.currentBooking?.status;
  },
  [Getters.GET_ADMISSION_COURSE]: (state) => (id) => {
    return state.currentBooking.admissionCourses.find(
      (course) => course.id === id,
    );
  },
  [Getters.GET_ADMISSION_COURSES](_, getters) {
    return getters[Getters.GET_CURRENT_BOOKING]?.admissionCourses ?? [];
  },

  [Getters.GET_EARLIEST_BOOKING_START_DATE](state) {
    const earliestBooking = state.bookings.sort((a, b) =>
      dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? -1 : 1,
    )[0];
    return earliestBooking?.startDate;
  },
  [Getters.GET_LATEST_BOOKING_END_DATE](state) {
    const latestBooking = state.bookings.sort((a, b) =>
      dayjs(a.endDate).isAfter(dayjs(b.endDate)) ? -1 : 1,
    )[0];
    return latestBooking?.endDate;
  },
  [Getters.GET_INTERRUPTION_LENGTH](state) {
    const interruptedBookings = state.bookings.find(
      (booking) => booking.status.name === ENROLMENT_STATUS_STRINGS.INTERRUPTED,
    );

    if (Object.keys(interruptedBookings).length) {
      return interruptedBookings.status.interruptionLength;
    }

    return 0;
  },
  [Getters.GET_CURRENT_BOOKING_START_DATE](state) {
    return state.currentBooking?.startDate;
  },
  [Getters.GET_CURRENT_REVOCATION_DEADLINE](state) {
    return state.currentBooking?.status?.revocationDeadline;
  },
  [Getters.GET_MATRICULATION_DATA](_state, getters) {
    return getters[Getters.GET_CURRENT_BOOKING]?.matriculation;
  },
  [Getters.GET_NEXT_MATRICULALTION_STATE](state) {
    return state.currentBooking?.matriculation?.nextMatriculationState;
  },
  [Getters.IS_MATRICULATION_ENABLED](_state, getters) {
    const matriculationState = getters[Getters.GET_NEXT_MATRICULALTION_STATE];
    return (
      matriculationState !== NEXT_MATRICULATION_STATE.NONE &&
      matriculationState != null
    );
  },
  [Getters.IS_ENROLMENT_ENABLED](state) {
    return state.currentBooking?.matriculation?.isEnrolable ?? false;
  },
  [Getters.GET_CURRENT_CONDITIONS](state) {
    return state.currentBooking?.conditions ?? [];
  },
};
