import { stringify } from 'qs';
import { ApiService } from '@epos/core-applets';

import { DOCBUILDER_API_ENDPOINTS } from '@/common/constants';
import { Actions, Getters, Mutations } from './types';

/**
 * Tag name for filtering document templates
 */
const TAG_NAME = 'Booking';

export default {
  async [Actions.FETCH_TAGS]({ commit }) {
    const response = await ApiService.get(DOCBUILDER_API_ENDPOINTS.TAGS, {
      params: {
        filter: {
          name: TAG_NAME,
        },
      },
      paramsSerializer: stringify,
    });
    const tags = response?.data?.data ?? [];
    commit(Mutations.SET_TAGS, tags);
  },
  async [Actions.FETCH_TEMPLATES]({ commit, getters, dispatch }) {
    await dispatch(Actions.FETCH_TAGS);

    const tags = getters[Getters.TAG_IDS];

    // no tags defined
    if (tags.length === 0) {
      commit(Mutations.SET_TEMPLATES, []);
      return;
    }
    const response = await ApiService.get(
      DOCBUILDER_API_ENDPOINTS.TEMPLATE_VERSION_LIST,
      {
        params: {
          filter: {
            tags: tags[0],
          },
          limit: 999,
        },
        paramsSerializer: stringify,
      },
    );
    const versions = response.data?.data ?? [];

    const publishedVersions = versions
      // use only published versions
      .filter((v) => !!v.publishedAt)
      // use only active versions
      .filter(
        (v) =>
          v.documentTemplate?.active || v.documentTemplate?.carePrintEnabled,
      );

    commit(Mutations.SET_TEMPLATES, publishedVersions);
  },

  async [Actions.FETCH_BINDINGS]({ commit }, templateId) {
    if (!templateId) {
      commit(Mutations.SET_BINDINGS, []);
      return;
    }

    const bindingParamUrl =
      DOCBUILDER_API_ENDPOINTS.VERSION_BINDING_PARAMS.replace(
        ':id',
        templateId,
      );
    const response = await ApiService.get(bindingParamUrl);
    const bindings = response?.data?.data ?? [];

    commit(Mutations.SET_BINDINGS, bindings);
  },

  async [Actions.GENERATE_DOCUMENT]({ state, getters, commit }) {
    const HEADER_FILNEAME_EXTRACTING_REGEX =
      /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    const template = getters[Getters.SELECTED_TEMPLATE];
    if (!template) {
      throw new Error('no template selected');
    }

    const templateId = template.documentTemplateId;
    const versionId = template.id;

    const path = DOCBUILDER_API_ENDPOINTS.GENERATE_PDF.replace(
      ':templateId',
      templateId,
    ).replace(':versionId', versionId);

    const bindingInput = state.parameters;

    const response = await ApiService.post(
      path,
      {
        bindingInput,
      },
      {
        responseType: 'blob',
      },
    );
    const header = response.headers?.['content-disposition'];
    const filename =
      header?.match(HEADER_FILNEAME_EXTRACTING_REGEX)?.[2] ??
      templateId.fileName;

    const file = response.data;

    // Reset input data
    commit(Mutations.RESET_DATA);

    return { filename, file };
  },
};
