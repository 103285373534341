<template>
  <div v-if="value && name" class="flex items-center py-2">
    <div>{{ name }}</div>
    <e-chip
      class="text-white"
      :class="{
        'bg-success': value.resolved,
        'bg-info': !value.resolved,
      }"
      >{{ text }}</e-chip
    >
  </div>
</template>

<script>
import { EChip } from '@careerpartner/nitro';

export const ConditionTypes = {
  QUALIFICATION_NOT_NOTARISED: 'qualification_not_notarised',
  CURRICULAR_ADMISSION_PROGRAM: 'curricular_admission_program',
  NON_CURRICULAR_ADMISSION_PROGRAM: 'non_curricular_admission_program',
  QUALIFICATION_NOT_CHECKED: 'qualification_not_checked',
};
const ConditionTexts = {
  [true]: 'Vollständig',
  [false]: 'Unvollständig',
};

export default {
  components: {
    EChip,
  },

  props: {
    value: { type: Object, default: undefined },
  },

  computed: {
    name() {
      const mapping = {
        [ConditionTypes.QUALIFICATION_NOT_CHECKED]: 'Eingereichte Unterlagen',
        [ConditionTypes.QUALIFICATION_NOT_NOTARISED]:
          'Notarielle Beglaubigung der HZB',
        [ConditionTypes.CURRICULAR_ADMISSION_PROGRAM]: 'Curriculare Zulassung',
        [ConditionTypes.NON_CURRICULAR_ADMISSION_PROGRAM]:
          'Außercurriculare Zulassung',
      };
      return mapping[this.value?.type];
    },
    text() {
      return ConditionTexts[this.value?.resolved];
    },
  },
};
</script>
