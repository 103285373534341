<template>
  <process-modal-form
    :id="id"
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitInterruption"
  >
    <template #form-content>
      <div class="flex flex-col space-y-6">
        <div>
          <validation-provider
            v-slot="{ errors }"
            name="Grund des Widerrufs"
            rules="required"
            immediate
          >
            <e-multiselect
              id="interruption-reason"
              v-model="recallReason"
              searchable
              :options="availableRecallReasons"
              option-label="label"
              track-by="key"
              label="Grund des Widerrufs"
              :errors="errors"
              required
              mapped
            />
          </validation-provider>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-x-6 mt-6">
        <div class="col-span-6">
          <validation-provider
            v-slot="{ errors }"
            name="Eingangsdatum Widerruf"
            :rules="{
              required: recallReason === recallInterruptionKeys.REVOCATION,
              date_format: true,
              after: { date: recallDateMin, inclusive: true },
              before: { date: recallDateMax, inclusive: true },
            }"
            slim
            immediate
          >
            <e-text-field
              id="recall-interruption-revocationDate"
              v-model="recallDate"
              type="date"
              label="Eingangsdatum Widerruf"
              wide
              :errors="errors"
              required
              :disabled="isReasonManualCorrection"
            />
          </validation-provider>
        </div>
      </div>
    </template>
    <template #submit-button-label>Speichern</template>
  </process-modal-form>
</template>

<script>
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { ETextField, EMultiselect } from '@careerpartner/nitro';
import { ValidationProvider } from 'vee-validate';
import {
  RECALL_INTERRUPTION_KEYS,
  RECALL_INTERRUPTION_REASONS,
} from '@/common/selectLists/interruptions';
import dayjs from 'dayjs';
import { API_ENDPOINTS, DATE_FORMAT } from '@/common/constants';
import { Getters as BookingGetters } from '@/store/modules/booking/types';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';
import { Actions as AppActions } from '@/store/modules/app/types';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';

export default {
  name: 'RecallInterruptionModal',
  components: {
    ETextField,
    EMultiselect,
    ValidationProvider,
    ProcessModalForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['recall-interruption'],
  data() {
    return {
      error: '',
      recallReason: '',
      recallDate: '',
      recallDateMin: null,
      recallDateMax: null,
      waitingForResponse: false,
    };
  },
  computed: {
    isReasonManualCorrection() {
      return this.recallReason === RECALL_INTERRUPTION_KEYS.MANUAL_CORRECTION;
    },
    availableRecallReasons() {
      return RECALL_INTERRUPTION_REASONS;
    },
    recallInterruptionKeys() {
      return RECALL_INTERRUPTION_KEYS;
    },
  },
  watch: {
    recallReason(val) {
      if (val !== RECALL_INTERRUPTION_KEYS.REVOCATION) {
        this.setDates();
      }
    },
  },
  beforeMount() {
    this.setDates();
  },
  methods: {
    setDates() {
      this.recallDate = dayjs().format(DATE_FORMAT);
      this.recallDateMin =
        this.$store.getters[`booking/${BookingGetters.GET_EARLIEST_BOOKING_START_DATE}`];

      this.recallDateMax =
        this.$store.getters[`booking/${BookingGetters.GET_LATEST_BOOKING_END_DATE}`];
    },
    async submitInterruption() {
      this.waitingForResponse = true;
      const profileId = this.$store.getters[`profile/${ProfileGetters.PROFILE_ID}`];

      const requestUrl = API_ENDPOINTS.RECALL_INTERRUPTION.replace(':profileId', profileId);

      const payload = {
        recallDate: this.recallDate,
        reason: this.recallReason,
      };

      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Beurlaubung wurde erfolgreich widerrufen.',
        });

        this.$emit('recall-interruption');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
