import { ApiService } from '@epos/core-applets';
import { ERROR_CONSTANTS, ERROR_TRANSLATION_TOKENS } from '@/common/constants';

function replaceEmptyStringWithNull(obj) {
  for (const k in obj) {
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      replaceEmptyStringWithNull(obj[k]);
    } else {
      if (obj[k] === '') {
        obj[k] = null;
      }
    }
  }

  return obj;
}

export function getApiError(e) {
  let result = ERROR_CONSTANTS.GENERAL_ERROR;

  if (e && e.response) {
    if (
      e.response.status === 400 &&
      e.response.data &&
      e.response.data.message
    ) {
      result = Array.isArray(e.response.data.message)
        ? ERROR_CONSTANTS.VALIDATION_ERROR
        : e.response.data.message;
    }

    if (e.response.status === 500) {
      result = ERROR_CONSTANTS.SERVER_NOT_AVAILABLE;
    }
  }

  const customTranslatedError = ERROR_TRANSLATION_TOKENS[result];
  if (customTranslatedError) {
    return customTranslatedError;
  }

  let errorString = ERROR_TRANSLATION_TOKENS[ERROR_CONSTANTS.GENERAL_ERROR];
  if (result) {
    errorString += ` (${result})`;
  }

  return errorString;
}

export const ApiServiceWrapper = {
  post: async (url, payload) => {
    payload = replaceEmptyStringWithNull(payload);
    return ApiService.post(url, payload);
  },
  put: async (url, payload) => {
    payload = replaceEmptyStringWithNull(payload);
    return ApiService.put(url, payload);
  },
  patch: async (url, payload) => {
    payload = replaceEmptyStringWithNull(payload);
    return ApiService.patch(url, payload);
  },
  get: async (url, payload) => {
    return ApiService.get(url, payload);
  },
  delete: async (url, payload) => {
    return ApiService.delete(url, payload);
  },
};
