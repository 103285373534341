<template>
  <tr class="odd:bg-blue-100">
    <td class="py-3 pl-3">
      <div class="flex flex-col">
        <p class="font-bold">
          {{ formatCode(item.year, item.season) }}
        </p>
        <p>
          {{ formatDate(item.startDate) }} -
          {{ formatDate(item.endDate) }}
        </p>
      </div>
    </td>
    <td>
      <e-tooltip :content="semesterToolTip">
        {{ semesterType }}
      </e-tooltip>
    </td>
    <td>{{ item.curriculum }}</td>
    <td>{{ item.stats.total }}</td>
    <td>{{ item.stats.regular }}</td>
    <td>{{ item.stats.vacation }}</td>
    <td>{{ item.stats.practical }}</td>
    <td>
      <div v-if="item.recognition" class="flex items-center space-x-3">
        <p class="font-bold">
          <e-icon icon="mdi-sigma" />
          {{ item.recognition.total }}
        </p>
        <e-tooltip content="Beruf">
          <p class="text-gray-700">
            <e-icon icon="mdi-briefcase-outline" />
            {{ item.recognition.work }}
          </p>
        </e-tooltip>
        <e-tooltip content="Studienleistung">
          <p class="text-gray-700">
            <e-icon icon="mdi-file-star-outline" />
            {{ item.recognition.study }}
          </p>
        </e-tooltip>
        <e-tooltip content="Ausland">
          <p class="text-gray-700">
            <e-icon icon="mdi-earth" />
            {{ item.recognition.foreign }}
          </p>
        </e-tooltip>
      </div>
    </td>
    <td>{{ studyState }}</td>
  </tr>
</template>

<script>
import { EIcon, ETooltip } from '@careerpartner/nitro';
import formatHelper from '@/mixins/formatHelper';
import { SEASON_SHORT, STUDY_STATES, SEMESTER_TYPES } from '@/common/constants';

export default {
  name: 'TableRow',
  components: {
    EIcon,
    ETooltip,
  },
  mixins: [formatHelper],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      semesterToolTip:
        'Semester-Typ kann über Anpassungen im Statistik-Reiter (Parallele Einschreibung/Externe Klausur/Externe Uni) geändert werden.',
    };
  },
  computed: {
    semesterType() {
      return SEMESTER_TYPES[this.item.type];
    },
    studyState() {
      return STUDY_STATES[this.item.studyState];
    },
  },
  methods: {
    formatCode(year, season) {
      const yearShort = String(year).slice(-2);
      const seasonShort = SEASON_SHORT[season] || season;
      return seasonShort + yearShort;
    },
  },
};
</script>
