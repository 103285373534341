<template>
  <process-modal-form
    :id="id"
    :invalid="invalid || !confirmChecked"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submit"
  >
    <template #form-content>
      <div class="grid grid-cols-6 gap-y-3 place-items-center grid-flow-row text-center">
        <div class="col-span-12">
          <img :src="revokeTimeModelChangeIcon" alt="" class="h-20" />
        </div>

        <div class="col-span-12 font-bold">
          Bist Du sicher, dass Du den Zeitmodell Wechsel widerrufen möchtest?
        </div>

        <span class="col-span-12 text-sm text-gray-700">
          Das Zeitmodell wird wie folgt zurückgesetzt:
        </span>
        <div class="col-span-12 w-3/4">
          <e-tooltip :content="oldTimeModel" class="w-full">
            <e-text-field
              id="oldTimeModel"
              v-model="oldTimeModel"
              label="Zeitmodell"
              wide
              disabled
            />
          </e-tooltip>
        </div>

        <div class="col-span-12 w-3/4 my-3">
          <validation-provider v-slot="{ errors }" rules="required|integer|min_value:0">
            <e-text-field
              id="time-model-change-course-fee"
              v-model="courseFee"
              type="number"
              label="Neuer Rechnungsbetrag"
              wide
              :errors="errors"
              required
            />
          </validation-provider>
        </div>

        <div class="col-span-6">
          <validation-provider name="revocation-confirmation" immediate>
            <e-checkbox
              id="revocation-confirmation"
              v-model="confirmation"
              rules="required"
              check-value="true"
              :options="[true, false]"
              label="Bestätige das Zurücksetzen des Zeitmodels"
            />
          </validation-provider>
        </div>
      </div>
    </template>

    <template #submit-button-label> Ausführen</template>
  </process-modal-form>
</template>

<script>
import { ECheckbox, ETextField, ETooltip } from '@careerpartner/nitro';
import { API_ENDPOINTS, TIME_MODELS } from '@/common/constants';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import { Actions as AppActions } from '@/store/modules/app/types';
import { ValidationProvider } from 'vee-validate';
import { imagePaths } from '@/common/content';
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';

export default {
  name: 'RevokeTimeModelChangeModal',
  components: {
    ProcessModalForm,
    ECheckbox,
    ETextField,
    ETooltip,
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  emits: ['time-model-change-revoked-successfully'],
  data() {
    return {
      loading: false,
      error: '',
      waitingForResponse: false,
      confirmation: false,
      oldTimeModel: '',
      courseFee: null,
    };
  },
  computed: {
    revokeTimeModelChangeIcon() {
      return imagePaths.REVOKE_PROGRAM_CHANGE_REQUEST;
    },
    confirmChecked() {
      return !!this.confirmation;
    },
  },

  beforeMount() {
    this.loadOldTimeModel();
  },

  methods: {
    async loadOldTimeModel() {
      const tmcr = await this.loadPcrByNewBookingId();

      if (tmcr?.oldTimeModel) {
        this.oldTimeModel = TIME_MODELS[tmcr.oldTimeModel];
      }
    },

    async loadPcrByNewBookingId() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.GET_TIME_MODEL_CHANGE_REQUESTS.replace(
        ':bookingId',
        this.$route.params.bookingId
      );

      try {
        const {
          data: { 0: tmcr },
        } = await ApiServiceWrapper.get(requestUrl);
        return tmcr;
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },

    async submit() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.REVOKE_TIME_MODEL_CHANGE.replace(
        ':bookingId',
        this.$route.params.bookingId
      );

      try {
        await ApiServiceWrapper.delete(requestUrl, {
          data: JSON.stringify({
            courseFee: parseInt(this.courseFee),
          }),
        });

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Widerruf des Zeitmodell Wechsel war erfolgreich.',
        });

        this.$emit('time-model-change-revoked-successfully');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
