import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/common/constants';

export default {
  methods: {
    getStartOfNextSemester(date) {
      let startDate = dayjs(date);

      const today = dayjs();

      while (startDate.isValid() && !startDate.isAfter(today)) {
        startDate = startDate.add(6, 'months');
      }
      return startDate.format(DATE_FORMAT);
    },
  },
};
