<template>
  <div>
    <div class="grid grid-cols-12 mt-20">
      <div class="col-span-6">
        <h3 class="font-bold text-blue-600 uppercase">Semestereinsicht</h3>
      </div>
    </div>
    <table class="w-full mt-6 table-auto">
      <TableHead :header-items="header" />
      <tbody>
        <TableRow v-for="item in booking.history" :key="item.id" :item="item" />
      </tbody>
    </table>
    <div v-if="!booking.history.length" class="flex flex-col items-center w-full mt-10 text-center">
      <img :src="emptyTableImg" alt="" />
      <p class="mt-4 font-bold">Noch keine Daten vorhanden</p>
      <p v-if="booking.status.name" class="max-w-xs mt-4">
        Semestereinsicht wird angelegt, sobald Immatrikulation abgeschlossen ist.
      </p>
    </div>
  </div>
</template>

<script>
import TableHead from '@/components/SemesterHistory/TableHead';
import TableRow from '@/components/SemesterHistory/TableRow';

export default {
  name: 'SemesterHistory',
  components: {
    TableRow,
    TableHead,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emptyTableImg:
        'https://res.cloudinary.com/iubh/image/upload/v1643799766/IT%20-%20Epos%20/illustrations/epos-booking-no-data-semestereinsicht.svg',
      header: [
        {
          label: 'Semester',
          tooltip: undefined,
        },
        {
          label: 'Typ',
          tooltip: undefined,
        },
        {
          label: 'Curriculum',
          tooltip: undefined,
        },
        {
          label: 'HS',
          tooltip: 'Hochschulsemester',
        },
        {
          label: 'FS',
          tooltip: 'Fachsemester',
        },
        {
          label: 'US',
          tooltip: 'Urlaubssemester',
        },
        {
          label: 'PS',
          tooltip: 'Praxissemester',
        },
        {
          label: 'Anerkennung',
          tooltip: undefined,
        },
        {
          label: 'Studienstatus',
          tooltip: undefined,
        },
      ],
    };
  }
};
</script>
