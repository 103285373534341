export const Mutations = {
  SET_BOOKINGS: 'setBookings',
  SET_CURRENT_BOOKING: 'setCurrentBooking',
};

export const Actions = {
  LOAD_BOOKINGS: 'loadBookings',
  LOAD_SINGLE_BOOKING: 'loadSingleBooking',
  LOAD_SINGLE_BOOKING_WITH_DELAY: 'loadSingleBookingWithDelay',
  GO_TO_METABASE_BOOKING_TABLE: 'goToMetabaseDashboard'
};

export const Getters = {
  GET_ADMISSION_COURSE: 'getAdmissionCourse',
  GET_ADMISSION_COURSES: 'getAdmissionCourses',
  GET_BOOKINGS: 'getBookings',
  GET_CURRENT_BOOKING: 'getCurrentBooking',
  GET_CURRENT_BOOKING_ID: 'getCurrentBookingId',
  GET_CURRENT_BOOKING_STATUS: 'getCurrentBookingStatus',
  GET_EARLIEST_BOOKING_START_DATE: 'getEarliestBookingStartDate',
  GET_CURRENT_CONDITIONS: 'getCurrentConditions',
  GET_INTERRUPTION_LENGTH: 'getInterruptionLength',
  GET_LATEST_BOOKING_END_DATE: 'getLatestBookingEndDate',
  GET_CURRENT_BOOKING_START_DATE: 'getCurrentBookingStartDate',
  GET_CURRENT_REVOCATION_DEADLINE: 'getCurrentRevocationDeadline',
  GET_MATRICULATION_DATA: 'getMatriculationData',
  GET_NEXT_MATRICULALTION_STATE: 'getNextMatriculationState',
  HAS_INTERRUPTED_BOOKINGS: 'hasInterruptedBookings',
  HAS_FAILED_ADMISSION_PROGRAM: 'hasFailedAdmissionProgram',
  IS_MATRICULATION_ENABLED: 'IsMatriculationEnabled',
  IS_ENROLMENT_ENABLED: 'IsEnrolmentEnabled',
  IS_ACADEMIC_PROGRAM: 'isAcademicProgram',
};
