import { Actions, Getters, Mutations } from './types';
import {
  Actions as AppActions,
  Mutations as AppMutations,
} from '@/store/modules/app/types';
import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper } from '@/utils/ApiServiceWrapper';

export default {
  async [Actions.LOAD_PROFILE]({ commit, dispatch }, profileId) {

    commit(`app/${AppMutations.INCREMENT_LOADING}`, {}, { root: true });

    const profileUrl = API_ENDPOINTS.CUSTOMER_PROFILE.replace(
      ':profileId',
      profileId,
    );

    try {
      const { data } = await ApiServiceWrapper.get(profileUrl);
      commit(Mutations.SET_PROFILE, data);
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    } finally {
      commit(`app/${AppMutations.DECREMENT_LOADING}`, {}, { root: true });
    }
  },

  async [Actions.LOAD_PROFILE_OWNERSHIP]({ commit, dispatch }, profileId) {
    const requestUrl = API_ENDPOINTS.PROFILE_OWNERSHIP.replace(
      ':profileId',
      profileId,
    );

    try {
      const { data: profileOwnership } = await ApiServiceWrapper.get(
        requestUrl,
      );

      commit(Mutations.SET_PROFILE_OWNERSHIP, profileOwnership);
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
  },

  async [Actions.RESIGN_OWNERSHIP](
    // eslint-disable-next-line no-unused-vars
    { dispatch, getters, commit },
    { profileId, resignationDescription },
  ) {
    const requestUrl = API_ENDPOINTS.PROFILE_OWNERSHIP.replace(
      ':profileId',
      profileId,
    );

    try {
      await ApiServiceWrapper.delete(requestUrl, {
        data: JSON.stringify({
          resignationDescription,
        }),
      });
      await dispatch(Actions.LOAD_PROFILE_OWNERSHIP, profileId);

      const isOwnedByCARENow = getters[Getters.IS_PROFILE_OWNED_BY_CARE];
      if (isOwnedByCARENow) {
        await dispatch(
          `app/${AppActions.UPDATE_SNACKBAR}`,
          {
            message: 'Das Profil wurde erfolgreich an CARE übertragen.',
          },
          { root: true },
        );

        await dispatch(Actions.OPEN_CARE_PROFILE);
      }
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    }
  },

  async [Actions.OPEN_CARE_PROFILE]({ state }) {
    window.open(state.profileOwnership.link, '_blank');
  },

  async [Actions.LOAD_PROFILE_ACCOMODATION]({ commit, dispatch }, profileId) {
    commit(`app/${AppMutations.INCREMENT_LOADING}`, {}, { root: true });

    const url = API_ENDPOINTS.PROFILE_ACCOMODATION.replace(
      ':profileId',
      profileId,
    );

    try {
      const { data } = await ApiServiceWrapper.get(url);
      const accomodation = data || null;

      commit(Mutations.SET_PROFILE_ACCOMODATION, accomodation);
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    } finally {
      commit(`app/${AppMutations.DECREMENT_LOADING}`, {}, { root: true });
    }
  },
  async [Actions.SET_PROFILE_ACCOMODATION]({ state, commit }, payload) {
    const profileAccomodation = state.profileAccomodation;
    const { profileId, requestPayload } = payload;

    const url = API_ENDPOINTS.PROFILE_ACCOMODATION.replace(
      ':profileId',
      profileId,
    );

    const { data } = await ApiServiceWrapper.put(url, requestPayload);

    commit(Mutations.SET_PROFILE_ACCOMODATION, data);

    const action = profileAccomodation === null ? 'aktiviert' : 'angepasst';

    commit(
      `app/${AppMutations.UPDATE_SNACKBAR}`,
      {
        message: `Nachteilsausgleich erfolgreich ${action}.`,
      },
      { root: true },
    );
  },
  async [Actions.DELETE_PROFILE_ACCOMODATION]({ commit }, profileId) {
    commit(`app/${AppMutations.INCREMENT_LOADING}`, {}, { root: true });

    const url = API_ENDPOINTS.PROFILE_ACCOMODATION.replace(
      ':profileId',
      profileId,
    );
    await ApiServiceWrapper.post(`${url}/delete`);

    commit(Mutations.SET_PROFILE_ACCOMODATION, null);

    commit(`app/${AppMutations.DECREMENT_LOADING}`, {}, { root: true });

    commit(
      `app/${AppMutations.UPDATE_SNACKBAR}`,
      {
        message: 'Nachteilsausgleich erfolgreich deaktiviert.',
      },
      { root: true },
    );
  },
};
