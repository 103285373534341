<template>
  <process-modal-form
    :id="id"
    :is-open="value"
    :invalid="invalid"
    title="In CARE bearbeiten"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @close="closeModal"
    @submit="submitOwnershipResignation"
  >
    <template #form-content>
      <div class="p-5 grid grid-cols-6 gap-y-3 place-items-center grid-flow-row text-center">
        <div class="col-span-12">
          <p class="mb-3">
            Mit dieser Aktion wird die Verwaltung des Studentenprofils an CARE übertragen. Ab diesem
            Zeitpunkt können Buchungen des Studierenden nur noch in CARE vorgenommen werden. Bist du
            sicher, dass du den Studierenden nach CARE übertragen möchtest?
          </p>
          <p>
            Wähle das benötigte Feature, das die Übertragung erfordert. Falls dein gewünschtes
            Feature nicht existiert, wähle "Sonstiges" und trage im Freitextfeld den fehlenden Grund
            ein.
          </p>
        </div>
      </div>

      <!-- Description -->
      <div>
        <validation-provider v-slot="{ errors }" name="Grund angeben" rules="required" immediate>
          <e-multiselect
            id="resign-ownership-description"
            v-model="cancelDescription"
            searchable
            :options="availableDescriptions"
            option-label="name"
            track-by="key"
            label="Grund angeben"
            :errors="errors"
            required
            mapped
          />
        </validation-provider>
      </div>

      <div v-if="isOtherDescription">
        <validation-provider
          v-slot="{ errors }"
          name="Neuen Grund angeben"
          rules="required|max:100"
          immediate
        >
          <e-text-field
            id="resign-ownership-manualEntry"
            v-model="cancelDescriptionOther"
            label="Neuen Grund angeben"
            :errors="errors"
          />
        </validation-provider>
      </div>
    </template>
    <template #submit-button-label>Profil transferieren</template>
  </process-modal-form>
</template>

<script>
import { EMultiselect, ETextField } from '@careerpartner/nitro';
import { extend, ValidationProvider } from 'vee-validate';
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { RESIGNATION_DESCRIPTION } from '@/common/constants';
import { max, required } from 'vee-validate/dist/rules';
import { Actions as ProfileActions } from '@/store/modules/profile/types';

extend('required', {
  ...required,
  message: 'Dieses Feld muss ausgefüllt sein.',
});

extend('max', {
  ...max,
  message: `Maximal {length} Zeichen erlaubt.`,
});

export default {
  name: 'OwnershipResignationModal',
  components: {
    ETextField,
    EMultiselect,
    ValidationProvider,
    ProcessModalForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    profileId: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'ownership-resigned'],
  data() {
    return {
      error: '',
      cancelDescription: '',
      cancelDescriptionOther: '',
      waitingForResponse: false,
    };
  },
  computed: {
    isOtherDescription() {
      return RESIGNATION_DESCRIPTION[this.cancelDescription] === RESIGNATION_DESCRIPTION.OTHER;
    },
    availableDescriptions() {
      return Object.entries(RESIGNATION_DESCRIPTION).map(([key, name]) => ({
        key,
        name,
      }));
    },
  },
  beforeMount() {
    this.resetModel();
  },
  methods: {
    resetModel() {
      this.cancelDescription = '';
      this.error = '';
    },
    closeModal() {
      this.$emit('input', false);
    },
    async submitOwnershipResignation() {
      let finalDescription = RESIGNATION_DESCRIPTION[this.cancelDescription];

      if (this.isOtherDescription) {
        finalDescription = this.cancelDescriptionOther;
      }

      await this.$store.dispatch(`profile/${ProfileActions.RESIGN_OWNERSHIP}`, {
        profileId: this.profileId,
        resignationDescription: finalDescription,
      });

      this.$emit('ownership-resigned');
    },
  },
};
</script>
