import { Mutations } from './types';

export default {
  [Mutations.SET_PROFILE](state, payload) {
    state.profile = payload;
  },
  [Mutations.SET_PROFILE_ACCOMODATION](state, payload) {
    state.profileAccomodation = payload;
  },
  [Mutations.SET_PROFILE_OWNERSHIP](state, payload) {
    state.profileOwnership = payload;
  },
};
