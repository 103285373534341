import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import profile from './modules/profile';
import booking from './modules/booking';
import document from './modules/document';
import currentUser from './modules/currentUser';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    profile,
    booking,
    document,
    currentUser,
  },
});
