import { Environment } from '@/common/constants';

export const METABASE_BASE_URLS = {
  [Environment.LOCAL]: 'https://metabase-dev.iu.org/dashboard/35-audit-activity-log',
  [Environment.DEV]: 'https://metabase-dev.iu.org/dashboard/35-audit-activity-log',
  [Environment.STAGE]: 'https://metabase-dev.iu.org/dashboard/35-audit-activity-log',
  [Environment.PROD]: 'https://metabase.iu.org/dashboard/115-audit-activity-log',
};

export const getMetabaseUrl = (env, profileId, bookingId) => {
  if (!env) {
    throw new Error(
      `Environment is undefined or null. Ensure that getEnvironment() is working correctly.`
    );
  }

  // Using Object.prototype to avoid eslint warning
  if (!Object.prototype.hasOwnProperty.call(METABASE_BASE_URLS, env)) {
    throw new Error(
      `Invalid environment: ${env}. Must be one of ${Object.keys(METABASE_BASE_URLS).join(', ')}`
    );
  }

  if (!profileId || !bookingId) {
    throw new Error('Both profileId and bookingId must be provided to generate the URL.');
  }

  return `${METABASE_BASE_URLS[env]}?profile_id=${profileId}&aggregate_type=Bookings&booking_id=${bookingId}`;
};
