<template>
  <thead>
    <tr class="border-b border-gray-700">
      <th
        v-for="item of headerItems"
        :key="item.label"
        class="pb-4 text-left text-blue-600 first:pl-3 truncate min-w-8"
      >
        <span class="uppercase">{{ item.label }}</span>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'TableHead',
  props: {
    headerItems: {
      type: Array,
      required: true,
    },
  },
};
</script>
