import {
  getCancellationReasons,
  CANCEL_REASON_KEYS,
} from '@/common/selectLists/cancellationReasons';
import { ENROLMENT_STATUS_STRINGS, FEATURES } from '@/common/constants';
import { isActive } from '@/utils/getEnvAndFeatures';

export default function (
  studyStartDate,
  revocationDeadline,
  studyEndDate,
  today,
  status,
  hasTrialPeriod,
  hasFailedAdmission,
) {
  const availableCancelReasons = [];

  const pushReason = (key) =>
    availableCancelReasons.push(
      getCancellationReasons().find((reason) => reason.key === key),
    );

  if (hasFailedAdmission) {
    pushReason(CANCEL_REASON_KEYS.ADMISSION_PROGRAM);
  }

  if (status === ENROLMENT_STATUS_STRINGS.SIGNED) {
    return availableCancelReasons;
  }

  if (today.isAfter(revocationDeadline) && today.isBefore(studyEndDate)) {
    pushReason(CANCEL_REASON_KEYS.REGULAR);
  }

  if (isActive(FEATURES.CLICK_AND_STUDY)) {
    if (status === ENROLMENT_STATUS_STRINGS.STUDYING_WITH_CONDITIONS) {
      pushReason(CANCEL_REASON_KEYS.DOCUMENTS_NOT_SUBMITTED);
      pushReason(CANCEL_REASON_KEYS.HZB);
    }
  } else {
    pushReason(CANCEL_REASON_KEYS.HZB);
  }

  if (today.isAfter(studyStartDate) && today.isBefore(studyEndDate)) {
    pushReason(CANCEL_REASON_KEYS.EXTRAORDINARY);
    pushReason(CANCEL_REASON_KEYS.FEES);
    pushReason(CANCEL_REASON_KEYS.HEALTH_INSURANCE);

    if (status === ENROLMENT_STATUS_STRINGS.STUDYING) {
      pushReason(CANCEL_REASON_KEYS.FAILED_EXAM);

      if (hasTrialPeriod) {
        pushReason(CANCEL_REASON_KEYS.FAILED_TRIAL);
      }
    }
  }

  return availableCancelReasons;
}
