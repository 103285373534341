<template>
  <e-button
    v-if="isAcademicProgram"
    variant="text"
    small
    data-test="booking__launch-matriculation"
    :disabled="!isMatriculationEnabled"
    @click="handleMatriculationClick"
  >
    <e-icon icon="rocket-launch-outline" class="mr-1" size="xs" />Immatrikulieren
  </e-button>
  <e-button
    v-else
    variant="text"
    small
    data-test="booking__launch-enrolment"
    :disabled="!isEnrolmentEnabled"
    @click="handleEnrolmentClick"
  >
    <e-icon icon="rocket-launch-outline" class="mr-1" size="xs" />Einschreiben
  </e-button>
</template>

<script>
import { EButton, EIcon } from '@careerpartner/nitro';
import { Getters } from '@/store/modules/booking/types';
import { MATRICULATE_OR_ENROL_EVENTS } from '@/common/events/matriculateOrEnrolEvents';
import { mapGetters } from 'vuex';

export default {
  name: 'MatriculateOrEnrolButton',
  components: { EButton, EIcon },
  emits: ['menu-button-clicked'],
  computed: {
    ...mapGetters('booking', {
      isAcademicProgram: Getters.IS_ACADEMIC_PROGRAM,
      status: Getters.GET_CURRENT_BOOKING_STATUS,
      isMatriculationEnabled: Getters.IS_MATRICULATION_ENABLED,
      isEnrolmentEnabled: Getters.IS_ENROLMENT_ENABLED,
    }),
  },
  methods: {
    handleMatriculationClick() {
      this.$emit('menu-button-clicked', MATRICULATE_OR_ENROL_EVENTS.START_MATRICULATION_PROCESS);
    },
    handleEnrolmentClick() {
      this.$emit('menu-button-clicked', MATRICULATE_OR_ENROL_EVENTS.START_ENROLMENT_PROCESS);
    },
  },
};
</script>
