<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-else>
    <care-ownership-info-banner />
    <!-- Prozessmodals -->
    <process-modal-wrapper
      id="graduation-process"
      v-slot="{ invalid }"
      v-model="showGraduationModal"
      title="Buchung graduieren"
    >
      <graduation-modal
        id="graduation-process"
        :invalid="invalid"
        :booking="currentBooking"
        @graduation-successful="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      id="add-admission"
      v-slot="{ invalid }"
      v-model="showAdmissionModal"
      :title="admissionModalTitle"
      @input="currentAdmission = null"
    >
      <admissions-modal
        id="upsert-admission-process-modal"
        :admission-id="admissionId"
        :invalid="invalid"
        :booking="currentBooking"
        @update-successful="closeModalAndReload"
      />
    </process-modal-wrapper>

    <delete-admission-modal
      id="delete-admission-process-modal"
      v-model="showDeleteAdmissionModal"
      :admission-id="admissionId"
      :booking="currentBooking"
      @deletion-successful="closeModalAndReload"
    />

    <process-modal-wrapper
      id="end-trial-period-process"
      v-slot="{ invalid }"
      v-model="showEndTrialPeriodModal"
      :title="endTrialPeriodModalTitle"
    >
      <end-trial-period-modal
        id="end-trial-period-process"
        :invalid="invalid"
        :booking="currentBooking"
        :action-name="actionName"
        @end-trial-period-successful="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      id="cancellation-process"
      v-slot="{ invalid }"
      v-model="showCancellationModal"
      title="Buchung kündigen"
    >
      <cancellation-modal
        id="cancellation-process"
        :booking="currentBooking"
        :invalid="invalid"
        @cancellation-successful="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      id="revocation-process"
      v-slot="{ invalid }"
      v-model="showRevocationModal"
      title="Buchung widerrufen"
    >
      <revocation-modal
        id="revocation-process"
        :invalid="invalid"
        :booking="currentBooking"
        @revocation-successful="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      id="recall-process"
      v-slot="{ invalid }"
      v-model="showRecallModal"
      title="Kündigung oder Widerruf zurückziehen"
    >
      <recall-modal
        id="recall-process"
        :invalid="invalid"
        :booking="currentBooking"
        @recall-successful="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      v-if="programChangeEnabled"
      id="program-change-modal"
      v-slot="{ invalid }"
      v-model="showProgramChangeModal"
      :auto-height="true"
      title="Studiengangwechsel"
    >
      <program-change-modal
        id="program-change"
        :invalid="invalid"
        :booking="currentBooking"
        @program-change-requested-successfully="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      v-if="programChangeEnabled"
      id="revoke-program-change-modal"
      v-slot="{ invalid }"
      v-model="showRevokeProgramChangeModal"
      :auto-height="true"
      title="Studiengangwechsel widerrufen"
    >
      <revoke-program-change-modal
        id="revoke-program-change"
        :invalid="invalid"
        :booking="currentBooking"
        @program-change-request-revoked-successfully="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      v-if="timeModelChangeEnabled"
      id="time-model-change-modal"
      v-slot="{ invalid }"
      v-model="showTimeModelChangeModal"
      :auto-height="true"
      title="Zeitmodellwechsel"
    >
      <time-model-change-modal
        id="time-model-change"
        :invalid="invalid"
        :booking="currentBooking"
        @time-model-change-successful="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      v-if="timeModelChangeEnabled"
      id="revoke-time-model-change-modal"
      v-slot="{ invalid }"
      v-model="showRevokeTimeModelChangeModal"
      :auto-height="true"
      title="Zeitmodellwechsel widerrufen"
    >
      <revoke-time-model-change-modal
        id="time-model-change"
        :invalid="invalid"
        :booking="currentBooking"
        @time-model-change-revoked-successfully="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      id="moving-study-start-modal"
      v-slot="{ invalid }"
      v-model="showUpdateStudyStartModal"
      title="Studienstart verschieben"
    >
      <update-study-start-process-modal
        id="moving-study-start"
        :invalid="invalid"
        :booking="currentBooking"
        @update-successful="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      id="document-download-modal"
      v-slot="{ invalid }"
      v-model="showDocumentDownloadModal"
      :auto-height="false"
      title="Dokumentendownload"
    >
      <document-download-modal
        id="document-download"
        :invalid="invalid"
        :booking="currentBooking"
        @update-successful="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      v-if="curriculumChangeEnabled"
      id="curriculum-change-modal"
      v-slot="{ invalid }"
      v-model="showCurriculumChangeModal"
      :auto-height="true"
      title="Curriculumwechsel"
    >
      <curriculum-change-modal
        id="curriculum-change"
        :invalid="invalid"
        :booking="currentBooking"
        @curriculum-change-requested-successfully="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      id="change-revocation-date"
      v-slot="{ invalid }"
      v-model="showChangeRevocationDateModal"
      title="Widerrufsfrist ändern"
    >
      <change-revocation-date-modal
        id="recall-process"
        :invalid="invalid"
        :booking="currentBooking"
        :auto-height="true"
        @changed-revocation-date-successfull="closeModalAndReload"
      />
    </process-modal-wrapper>

    <process-modal-wrapper
      id="add-recognition-modal"
      v-slot="{ invalid }"
      v-model="showRecognitionModal"
      title="Fachsemester anerkennen"
    >
      <add-recognition-modal
        id="add-recognition"
        :invalid="invalid"
        :booking="currentBooking"
        :auto-height="true"
        @added-recognition-successfully="closeModalAndReload"
      />
    </process-modal-wrapper>

    <cancel-curriculum-change-modal
      v-if="curriculumChangeEnabled"
      id="cancel-curriculum-change-modal"
      v-model="showCancelCurriculumChangeModal"
      :booking="currentBooking"
      @curriculum-change-canceled-successfully="closeModalAndReload"
    />

    <process-modal-wrapper
      id="cancellation-process"
      v-slot="{ invalid }"
      v-model="showOwnershipModal"
      title="In Care bearbeiten"
    >
      <ownership-resignate-modal
        id="resign-ownership-modal"
        :profile-id="$route.params.id"
        :invalid="invalid"
        @ownership-resigned="closeModalAndReload"
      />
    </process-modal-wrapper>

    <booking-detail-toolbar @toolbar-action="handleEvent" />

    <error-banner />
    <div class="mt-12 z-100">
      <!-- Allgemeine Info -->
      <general-booking-details :booking="currentBooking" />

      <!-- Zulassungsprogramme -->
      <admission-courses
        v-if="currentBooking.admissionCourses.length"
        :admission-courses="currentBooking.admissionCourses"
        @edit-admission="editAdmission"
        @delete-admission="deleteAdmission"
      />
      <!-- Semestereinsicht -->
      <semester-history :booking="currentBooking"
      />
      <!--recognitionHistory  -->
      <recognition-history 
      :booking="currentBooking" 
      @show-recognition-modal="handleEvent"
      />
    </div>
  </div>
</template>

<script>
import {
  Actions as BookingActions,
  Getters as BookingGetters,
} from '@/store/modules/booking/types';
import AdmissionCourses from '@/components/AdmissionCourses/Index';
import AdmissionsModal from '@/components/AdmissionCourses/AdmissionsModal';
import DeleteAdmissionModal from '@/components/AdmissionCourses/DeleteAdmissionModal';
import BookingDetailToolbar from '@/components/BookingDetailToolbar/Index';
import CancellationModal from '@/components/ProcessModals/CancellationModal';
import CurriculumChangeModal from '@/components/ProcessModals/CurriculumChangeModal';
import ErrorBanner from '@/components/shared/ErrorBanner';
import formatHelper from '@/mixins/formatHelper';
import GeneralBookingDetails from './GeneralBookingDetails';
import Loader from '@/components/shared/Loader';
import RecallModal from '@/components/ProcessModals/RecallCancellationModal';
import SemesterHistory from '@/components/SemesterHistory/Index';
import RecognitionHistory from '@/components/RecognitionHistory/Index';

import { Getters as AppGetters } from '@/store/modules/app/types';
import { END_BOOKING_EVENTS } from '@/common/events/endBookingEvents';
import RevocationModal from '@/components/ProcessModals/RevocationModal';
import GraduationModal from '@/components/ProcessModals/GraduationModal';
import ProcessModalWrapper from '@/components/ProcessModals/shared/ProcessModalWrapper';
import { MATRICULATE_OR_ENROL_EVENTS } from '@/common/events/matriculateOrEnrolEvents';
import { ADMISSION_PROGRAM_EVENTS } from '@/common/events/admissionProgramEvents';
import UpdateStudyStartProcessModal from '@/components/ProcessModals/UpdateStudyStartProcessModal';
import { EDIT_CONTRACT_EVENTS } from '@/common/events/editContractEvents';
import { TRIAL_PERIOD_EVENTS } from '@/common/events/trialStudyEvents';
import { OWNERSHIP_EVENTS } from '@/common/events/ownershipEvents';
import EndTrialPeriodModal from '@/components/ProcessModals/EndTrialPeriodModal';
import { DOCUMENT_EVENTS } from '@/common/events/documentEvents';
import DocumentDownloadModal from '@/components/ProcessModals/DocumentDownloadModal';
import CancelCurriculumChangeModal from '@/components/ProcessModals/CancelCurriculumChangeModal.vue';
import ProgramChangeModal from '@/components/ProcessModals/ProgramChangeModal.vue';
import RevokeProgramChangeModal from '@/components/ProcessModals/RevokeProgramChangeModal.vue';
import ChangeRevocationDateModal from '@/components/ProcessModals/ChangeRevocationDateModal.vue';
import TimeModelChangeModal from '@/components/ProcessModals/TimeModelChangeModal.vue';
import RevokeTimeModelChangeModal from '@/components/ProcessModals/RevokeTimeModelChangeModal.vue';
import OwnershipResignateModal from '@/components/ProcessModals/OwnershipResignationModal';
import { mapGetters } from 'vuex';
import { EDIT_BOOKING_EVENTS } from '@/common/events/editBookingEvents';
import { isActive } from '@/utils/getEnvAndFeatures';
import { FEATURES } from '@/common/constants';
import AddRecognitionModal from '@/components/ProcessModals/AddRecognitionModal';
import CareOwnershipInfoBanner from '@/components/CareOwnershipInfoBanner';

export default {
  name: 'BookingDetail',
  components: {
    CareOwnershipInfoBanner,
    AddRecognitionModal,
    EndTrialPeriodModal,
    AdmissionCourses,
    AdmissionsModal,
    DeleteAdmissionModal,
    BookingDetailToolbar,
    CancellationModal,
    ProgramChangeModal,
    CurriculumChangeModal,
    ChangeRevocationDateModal,
    ErrorBanner,
    GeneralBookingDetails,
    GraduationModal,
    Loader,
    ProcessModalWrapper,
    RecallModal,
    RevocationModal,
    SemesterHistory,
    RecognitionHistory,
    UpdateStudyStartProcessModal,
    OwnershipResignateModal,
    DocumentDownloadModal,
    CancelCurriculumChangeModal,
    RevokeProgramChangeModal,
    TimeModelChangeModal,
    RevokeTimeModelChangeModal,
  },
  mixins: [formatHelper],
  data() {
    return {
      actionName: '',
      currentAdmission: null,
      showAdmissionModal: false,
      showDeleteAdmissionModal: false,
      showEndTrialPeriodModal: false,
      showCancellationModal: false,
      showGraduationModal: false,
      showRecallModal: false,
      showRevocationModal: false,
      showUpdateStudyStartModal: false,
      showDocumentDownloadModal: false,
      showProgramChangeModal: false,
      showRevokeProgramChangeModal: false,
      showTimeModelChangeModal: false,
      showRevokeTimeModelChangeModal: false,
      showCurriculumChangeModal: false,
      showCancelCurriculumChangeModal: false,
      showChangeRevocationDateModal: false,
      showRecognitionModal: false,
      showOwnershipModal: false,
    };
  },
  computed: {
    ...mapGetters('booking', {
      currentBooking: BookingGetters.GET_CURRENT_BOOKING,
    }),
    endTrialPeriodModalTitle() {
      if (this.actionName === TRIAL_PERIOD_EVENTS.ON_ADD_TRIAL) {
        return 'Probestudium hinzufügen';
      }
      if (this.actionName === TRIAL_PERIOD_EVENTS.ON_GRADUATE_TRIAL) {
        return 'Bestehen von Probestudium';
      }
      if (this.actionName === TRIAL_PERIOD_EVENTS.ON_END_TRIAL_WITH_DOCUMENTS) {
        return 'Probestudium beenden, da Unterlagen nachgereicht';
      }
      if (this.actionName === TRIAL_PERIOD_EVENTS.ON_REVOKE_TRIAL) {
        return 'Probestudium widerrufen  aufgrund von Korrektur';
      }
      return '';
    },
    loading() {
      return this.$store.getters[`app/${AppGetters.LOADING}`];
    },
    isExistingAdmission() {
      return !!this.currentAdmission;
    },
    admissionId() {
      return this.currentAdmission?.id || '';
    },
    admissionModalTitle() {
      if (!this.currentAdmission?.curriculum?.id) {
        return 'Zulassung starten';
      }

      if (this.isExistingAdmission) {
        return 'Zulassung bearbeiten';
      } else {
        return 'Zulassung hinzufügen';
      }
    },
    curriculumChangeEnabled() {
      return isActive(FEATURES.CHANGE_CURRICULUM) === true;
    },
    programChangeEnabled() {
      return isActive(FEATURES.CHANGE_PROGRAM) === true;
    },
    timeModelChangeEnabled() {
      return isActive(FEATURES.CHANGE_TIME_MODEL) === true;
    },
  },
  async beforeMount() {
    await this.loadBooking();
  },
  methods: {
    [MATRICULATE_OR_ENROL_EVENTS.START_MATRICULATION_PROCESS]() {
      this.$router.push({ path: 'matriculate', append: true });
    },
    [MATRICULATE_OR_ENROL_EVENTS.START_ENROLMENT_PROCESS]() {
      this.$router.push({ path: 'matriculate', append: true });
    },
    [TRIAL_PERIOD_EVENTS.ON_ADD_TRIAL]() {
      this.showEndTrialPeriodModal = true;
    },
    [TRIAL_PERIOD_EVENTS.ON_GRADUATE_TRIAL]() {
      this.showEndTrialPeriodModal = true;
    },
    [TRIAL_PERIOD_EVENTS.ON_END_TRIAL_WITH_DOCUMENTS]() {
      this.showEndTrialPeriodModal = true;
    },
    [TRIAL_PERIOD_EVENTS.ON_REVOKE_TRIAL]() {
      this.showEndTrialPeriodModal = true;
    },
    [END_BOOKING_EVENTS.ON_CANCELLATION]() {
      this.showCancellationModal = true;
    },
    [END_BOOKING_EVENTS.ON_REVOCATION]() {
      this.showRevocationModal = true;
    },
    [END_BOOKING_EVENTS.ON_RECALL_REVOCATION]() {
      this.showRecallModal = true;
    },
    [END_BOOKING_EVENTS.ON_RECALL_CANCELLATION]() {
      this.showRecallModal = true;
    },
    [END_BOOKING_EVENTS.ON_GRADUATION]() {
      this.showGraduationModal = true;
    },
    [ADMISSION_PROGRAM_EVENTS.ADD_ADMISSION]() {
      this.showAdmissionModal = true;
    },
    [EDIT_BOOKING_EVENTS.ON_CHANGE_PROGRAM]() {
      this.showProgramChangeModal = true;
    },
    [EDIT_BOOKING_EVENTS.ON_CHANGE_PROGRAM_REVOKED]() {
      this.showRevokeProgramChangeModal = true;
    },
    [EDIT_BOOKING_EVENTS.ON_CHANGE_TIME_MODEL]() {
      this.showTimeModelChangeModal = true;
    },
    [EDIT_BOOKING_EVENTS.ON_REVOKE_TIME_MODEL_CHANGE]() {
      this.showRevokeTimeModelChangeModal = true;
    },
    [EDIT_CONTRACT_EVENTS.ON_CHANGE_STUDY_START]() {
      this.showUpdateStudyStartModal = true;
    },
    [DOCUMENT_EVENTS.ON_SHOW_DOCUMENT_DOWNLOAD]() {
      this.showDocumentDownloadModal = true;
    },
    [EDIT_BOOKING_EVENTS.ON_CHANGE_CURRICULUM]() {
      this.showCurriculumChangeModal = true;
    },
    [EDIT_BOOKING_EVENTS.ON_CANCEL_CURRICULUM_CHANGE]() {
      this.showCancelCurriculumChangeModal = true;
    },
    [EDIT_CONTRACT_EVENTS.ON_CHANGE_REVOCATION_DATE]() {
      this.showChangeRevocationDateModal = true;
    },
    [EDIT_BOOKING_EVENTS.ON_ADD_REVOCATION]() {
      this.showRecognitionModal = true;
    },
    [OWNERSHIP_EVENTS.ON_RESIGNATE_OWNERSHIP]() {
      this.showOwnershipModal = true;
    },

    handleEvent(actionName) {
      this.actionName = actionName;
      return this[actionName]();
    },

    async closeModalAndReload() {
      this.actionName = '';
      this.currentAdmission = '';
      this.showAdmissionModal = false;
      this.showDeleteAdmissionModal = false;
      this.showEndTrialPeriodModal = false;
      this.showCancellationModal = false;
      this.showGraduationModal = false;
      this.showRecallModal = false;
      this.showRevocationModal = false;
      this.showUpdateStudyStartModal = false;
      this.showDocumentDownloadModal = false;
      this.showProgramChangeModal = false;
      this.showRevokeProgramChangeModal = false;
      this.showTimeModelChangeModal = false;
      this.showRevokeTimeModelChangeModal = false;
      this.showCurriculumChangeModal = false;
      this.showCancelCurriculumChangeModal = false;
      this.showChangeRevocationDateModal = false;
      this.showRecognitionModal = false;
      this.showOwnershipModal = false;

      await this.loadBooking();
    },
    async loadBooking() {
      await this.$store.dispatch(
        `booking/${BookingActions.LOAD_SINGLE_BOOKING}`,
        this.$route.params.bookingId,
      );
    },
    editAdmission(admissionProgram) {
      this.currentAdmission = admissionProgram;
      this.showAdmissionModal = true;
    },
    deleteAdmission(admissionProgram) {
      this.currentAdmission = admissionProgram;
      this.showDeleteAdmissionModal = true;
    },
  },
};
</script>
