import dayjs from 'dayjs';

const format = (date) => dayjs(date).format('DD.MM.YYYY');

export const validationErrorMessages = {
  length: (val) => `Maximal ${val} Zeichen erlaubt.`,
  nameFormatting: 'Unerlaubte Sonderzeichen. Erlaubt: -_.',
  numeric: 'Nur Ziffern erlaubt.',
  required: 'Dieses Feld muss ausgefüllt sein.',
  maxTerms: 'Maximaler Wert überschritten.',
  minTerms: 'Minimaler Wert unterschritten.',
  before: (date, inclusive = false) =>
    `Das Datum muss vor${inclusive ? ' oder an' : ''} dem ${format(
      date,
    )} sein.`,
  after: (date, inclusive = false) =>
    `Das Datum muss nach${inclusive ? ' oder an' : ''} dem ${format(
      date,
    )} sein.`,
};
