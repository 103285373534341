<template>
  <div class="mb-5">
    <validation-provider v-slot="{ errors }" :rules="{ required }" slim>
      <component
        :is="component"
        v-bind="{ ...componentProps, errors, id: formattedId }"
        @input="(newValue) => $emit('input', newValue)"
      />
    </validation-provider>
  </div>
</template>

<script>
import { ETextField, EDatepicker, ETextarea, EMultiselect } from '@careerpartner/nitro';
import { ValidationProvider } from 'vee-validate';

const BindingType = {
  DATE: 'date',
  MULTILINE: 'multiline',
  TEXT: 'string',
  SELECT: 'select',
};

const BindingTypeMapping = {
  [BindingType.DATE]: EDatepicker,
  [BindingType.MULTILINE]: ETextarea,
  [BindingType.TEXT]: ETextField,
  [BindingType.SELECT]: EMultiselect,
};

export default {
  name: 'BindingParam',
  components: { ValidationProvider },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    binding: { type: Object, required: true },
    value: { type: String, default: undefined },
  },
  emits: ['input'],
  computed: {
    formattedId() {
      return `binding-${this.id}`;
    },
    bindingType() {
      return this.binding.type ?? BindingType.TEXT;
    },
    component() {
      return BindingTypeMapping[this.bindingType] ?? BindingTypeMapping[BindingType.TEXT];
    },
    required() {
      return this.binding?.required === true;
    },
    componentProps() {
      const props = {
        value: this.value ?? this.binding.default,
        label: this.binding.description || this.binding.name,
        required: this.required,
      };

      if (this.bindingType === BindingType.MULTILINE) {
        props.minLength = 1;
        props.maxLength = 1000;
      }

      if (this.bindingType === BindingType.SELECT) {
        props.options = this.binding.values;
        props['option-label'] = 'label';
        props['track-by'] = 'value';
        props.mapped = true;
      }

      return props;
    },
  },
  mounted() {
    if (!this.value && this.binding.default) {
      this.$emit('input', this.binding.default);
    }
  },
};
</script>
