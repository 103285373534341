<template>
  <div>
    <div>
      <span v-if="description">{{ description }}</span>
      <span v-else class="italic">Kein Titel vorhanden</span>
    </div>
    <div class="min-w-max">
      <admission-status class="block" :value="status" />
    </div>
  </div>
</template>

<script>
import AdmissionStatus from './AdmissionStatus.vue';

export default {
  name: 'AdmissionDisplay',
  components: {
    AdmissionStatus,
  },
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    description() {
      return this.value?.program?.title;
    },
    status() {
      return this.value?.status;
    },
  },
};
</script>
