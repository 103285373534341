import { Mutations } from './types';

export default {
  [Mutations.SET_TEMPLATES](state, templates) {
    state.templates = templates;
  },
  [Mutations.SET_TAGS](state, tags) {
    state.tags = tags;
  },
  [Mutations.SET_BINDINGS](state, bindings) {
    state.bindings = bindings;
  },
  [Mutations.SET_QUERIES](state, queries) {
    state.queries = queries;
  },
  [Mutations.SET_VARIABLES](state, variables) {
    state.variables = variables;
  },
  [Mutations.RESET_DATA](state) {
    state.selectedTemplateId = null;
    state.parameters = null;
  },
};
