import { getField, updateField } from 'vuex-map-fields';
import mutations from './mutations';
import actions from './actions';
import state from './state';
import getters from './getters';

export default {
  namespaced: true,

  state: state(),
  actions,
  mutations: { ...mutations, updateField },
  getters: { ...getters, getField },
};
