<template>
  <div
    class="rounded flex items-center overflow-hidden min-h-12"
    :class="statusVariant.background"
  >
    <div
      class="px-3 self-stretch grid place-items-center"
      :class="statusVariant.icon.background"
    >
      <e-icon :icon="statusVariant.icon.name" class="text-2xl" />
    </div>
    <p class="ml-3 font-bold">
      {{ content }}
    </p>
  </div>
</template>

<script>
import { EIcon } from '@careerpartner/nitro';
import { ENROLMENT_STATUS_STRINGS } from '@/common/constants';

export default {
  name: 'StatusChip',
  components: {
    EIcon,
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusVariant() {
      const status = {
        background: [],
        icon: {
          background: '',
          name: '',
        },
      };

      const bgYellow = ['bg-yellow-50', 'text-yellow-600'];
      const iconYellow = 'bg-yellow-200';
      const bgOrange = ['bg-orange-50', 'text-orange-600'];
      const iconOrange = 'bg-orange-200';
      const bgGreen = ['bg-green-50', 'text-green-600'];
      const iconGreen = 'bg-green-200';
      const bgPink = ['bg-pink-50', 'text-pink-600'];
      const iconPink = 'bg-pink-200';

      switch (this.variant) {
        case ENROLMENT_STATUS_STRINGS.SIGNED:
          status.background = bgYellow;
          status.icon.background = iconYellow;
          status.icon.name = 'file-document-edit-outline';
          break;
        case ENROLMENT_STATUS_STRINGS.IN_ADMISSION:
          status.background = bgOrange;
          status.icon.background = iconOrange;
          status.icon.name = 'wrench-outline';
          break;
        case ENROLMENT_STATUS_STRINGS.INTERRUPTED:
          status.background = bgGreen;
          status.icon.background = iconGreen;
          status.icon.name = 'island';
          break;
        case ENROLMENT_STATUS_STRINGS.ENROLED:
          status.background = bgOrange;
          status.icon.background = iconOrange;
          status.icon.name = 'rocket-launch-outline';
          break;
        case ENROLMENT_STATUS_STRINGS.ENROLED_WITH_CONDITIONS:
          status.background = bgOrange;
          status.icon.background = iconOrange;
          status.icon.name = 'note-alert-outline';
          break;
        case ENROLMENT_STATUS_STRINGS.STUDYING:
          status.background = bgGreen;
          status.icon.background = iconGreen;
          status.icon.name = 'book-open-variant';
          break;
        case ENROLMENT_STATUS_STRINGS.STUDYING_WITH_CONDITIONS:
          status.background = bgOrange;
          status.icon.background = iconOrange;
          status.icon.name = 'clipboard-alert-outline';
          break;
        case ENROLMENT_STATUS_STRINGS.ENDED:
          status.background = bgPink;
          status.icon.background = iconPink;
          status.icon.name = 'bank-off-outline';
          break;
        case ENROLMENT_STATUS_STRINGS.GRADUATED:
          status.background = bgPink;
          status.icon.background = iconPink;
          status.icon.name = 'bank-off-outline';
          break;
      }

      /*  if (this.variant === 'enroled') {
        color.background = ['bg-green-200', 'text-green-600']
        color.icon = 'bg-green-400'
      } else if (this.variant === 'contractPending') {
      } else if (this.variant === 'pending') {
        color.background = ['bg-orange-200', 'text-orange-600']
        color.icon = 'bg-orange-400'
      } else if (this.variant === 'finished') {
        color.background = ['bg-flamingo-lightest', 'text-flamingo']
        color.icon = 'bg-flamingo-lighter'
      } */

      return status;
    },
  },
};
</script>
