import { OWNING_SYSTEM } from '@/common/constants';

export default () => ({
  profile: {
    academyId: '',
    damFolderUrl: null,
    id: '',
    person: {
      sex: '',
      honorificPrefix: '',
      givenName: '',
      familyName: '',
      birthDate: '',
      birthName: '',
      nationality: [''],
    },
  },
  profileAccomodation: null,
  profileOwnership: {
    ownership: OWNING_SYSTEM.EPOS,
    link: '', // link will be set given the ownership === OWNING_SYSTEM.CARE
  },
});
