import { Getters } from './types';

export default {
  [Getters.TEMPLATES](state) {
    return state.templates;
  },
  [Getters.TAGS](state) {
    return state.tags;
  },
  [Getters.TAG_IDS](state) {
    return state.tags.map((tag) => tag.id);
  },
  [Getters.BINDINGS](state) {
    return state.bindings;
  },
  [Getters.SELECTED_TEMPLATE](state) {
    return state.templates.find((t) => t.id === state.selectedTemplateId);
  },
};
