<template>
  <e-snackbar
    v-model="show"
    :variant="error ? 'error' : 'success'"
    :timer-length="6000"
  >
    <template #message>
      {{ message }}
    </template>
    <template #actions>
      <e-button
        ref="profile-snackbar-close"
        variant="blank"
        @click="show = false"
        @keyup.esc="show = false"
      >
        <span class="text-white"><e-icon icon="mdi-close"></e-icon></span>
      </e-button>
    </template>
  </e-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  Actions as AppActions,
  Getters as AppGetters,
} from '@/store/modules/app/types';
import { ESnackbar, EButton, EIcon } from '@careerpartner/nitro';

export default {
  name: 'Snackbar',
  components: {
    EButton,
    EIcon,
    ESnackbar,
  },
  data() {
    return {
      show: false,
      message: '',
      error: false,
    };
  },
  computed: {
    ...mapGetters('app', { snackbar: AppGetters.SNACKBAR }),
  },
  watch: {
    snackbar: {
      handler({ message, error = false }) {
        if (message !== '') {
          this.message = message;
          this.error = error;
          this.show = true;
          this.updateSnackbar({ message: '' });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('app', { updateSnackbar: AppActions.UPDATE_SNACKBAR }),
  },
};
</script>
