<template>
  <div :class="column ? '' : 'inline-flex'">
    <slot></slot>
    <dt :class="headlineBold ? 'font-bold' : ''">
      {{ headline }}
    </dt>
    <dd class="text-gray-700" :class="column ? '' : 'ml-1'">
      <e-tooltip v-if="contentTooltip" :content="contentTooltip">
        {{ content }}
      </e-tooltip>
      <template v-else>
        {{ content }}
      </template>
    </dd>
  </div>
</template>

<script>
import { ETooltip } from '@careerpartner/nitro';
export default {
  name: 'DefinitionItem',
  components: {
    ETooltip,
  },
  props: {
    column: {
      type: Boolean,
      default: true,
    },
    headline: {
      type: String,
      required: true,
    },
    headlineBold: {
      type: Boolean,
      default: true,
    },
    content: {
      type: String,
      required: true,
    },
    contentTooltip: {
      type: String,
      required: false,
      default: () => null,
    },
  },
};
</script>
