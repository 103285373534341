import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper } from './ApiServiceWrapper';
import store from '@/store';
import { Environment } from '@/common/constants';
import { Actions as AppActions, Mutations as AppMutations } from '@/store/modules/app/types';

const enabledFeatures = [];

export const isActive = (feature) => enabledFeatures.includes(feature);
export const isInactive = (feature) => !isActive(feature);

export const getEnvAndFeatures = async () => {
  const versioningUrl = API_ENDPOINTS.VERSIONING;

  try {
    const { data: result } = await ApiServiceWrapper.get(versioningUrl);
    const { environment, featureFlags } = result;

    if (!Object.values(Environment).includes(environment)) {
      throw new Error(`Unrecognized backend environment: ${environment}`);
    }

    // Set enabled features
    enabledFeatures.length = 0;
    enabledFeatures.push(...featureFlags.filter((ff) => ff.isActive).map((ff) => ff.featureFlag));

    // Commit environment to Vuex
    store.commit(`app/${AppMutations.SET_ENVIRONMENT}`, environment);

    return environment;
  } catch (error) {
    store.dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
  }
};
