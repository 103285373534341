export const Mutations = {
  SET_PROFILE: 'setProfile',
  SET_PROFILE_ACCOMODATION: 'setProfileAccomodation',
  SET_PROFILE_OWNERSHIP: 'setProfileOwnership',
};

export const Actions = {
  LOAD_PROFILE: 'loadProfile',
  SET_PROFILE_ACCOMODATION: 'setProfileAccomodation',
  DELETE_PROFILE_ACCOMODATION: 'deleteProfileAccomodation',
  LOAD_PROFILE_ACCOMODATION: 'loadProfileAccomodation',
  LOAD_PROFILE_OWNERSHIP: 'loadProfileOwnership',
  RESIGN_OWNERSHIP: 'resignOwnership',
  OPEN_CARE_PROFILE: 'openCareProfile',
};

export const Getters = {
  FULL_NAME: 'fullName',
  PROFILE_DAM_FOLDER_URL: 'damUrl',
  PROFILE_ID: 'profileId',
  PROFILE_ACCOMODATION: 'profileAccomodation',
  PROFILE_ACCOMODATION_ACTIVE: 'profileAccomodationActive',
  PROFILE_ACADEMY_ID: 'academyId',
  PROFILE: 'profile',
  IS_PROFILE_OWNED_BY_CARE: 'isProfileOwnedByCARE',
};
