import { Mutations } from './types';

export default {
  [Mutations.SET_BOOKINGS](state, payload) {
    state.bookings = payload;
  },
  [Mutations.SET_CURRENT_BOOKING](state, payload) {
    state.currentBooking = payload;
  },
};
