import dayjs from 'dayjs';

export default {
  methods: {
    sortCurricula(curricula) {
      return curricula.sort((a, b) => {
        return dayjs(b.earliestStudyStart) - dayjs(a.earliestStudyStart);
      });
    },
  },
};
