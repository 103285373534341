var render = function render(){var _vm=this,_c=_vm._self._c;return _c('process-modal-form',{attrs:{"id":_vm.id,"invalid":_vm.invalid,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitRevocation},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [(_vm.isRevocationDateOver)?_c('div',{staticClass:"banner"},[_c('e-icon',{attrs:{"icon":"information","size":"xl"}}),_vm._v(" Widerrufsfrist ist bereits abgelaufen. ")],1):_vm._e(),_c('div',{staticClass:"flex flex-col space-y-6"},[_c('validation-provider',{attrs:{"name":"Kategorie des Kündigungsgrundes","rules":{ required: true },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-multiselect',{attrs:{"id":"revocation-categories","searchable":"","options":_vm.availableRevocationCategories,"option-label":"name","track-by":"key","label":"Kategorie des Widerrufsgrundes","errors":errors,"required":"","mapped":""},model:{value:(_vm.revocationCategory),callback:function ($$v) {_vm.revocationCategory=$$v},expression:"revocationCategory"}})]}}])}),(_vm.revocationCategory !== 'other')?_c('div',[_c('validation-provider',{attrs:{"name":"Beschreibung des Kündigungsgrundes","rules":{
            required: !!_vm.revocationCategory,
          },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-multiselect',{attrs:{"id":"revocation-description","searchable":"","options":_vm.availableRevocationDescriptions,"option-label":"name","track-by":"name","label":"Beschreibung des Kündigungsgrundes","errors":errors,"required":"","mapped":"","disabled":!_vm.revocationCategory || _vm.revocationCategory.length === 0},model:{value:(_vm.revocationDescription),callback:function ($$v) {_vm.revocationDescription=$$v},expression:"revocationDescription"}})]}}],null,false,3294671077)})],1):_c('div',[_c('validation-provider',{attrs:{"name":"Beschreibung des Widerrufsgrundes","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"cancellation-manualEntry","label":"Beschreibung des Widerrufsgrundes","errors":errors},model:{value:(_vm.revocationDescription),callback:function ($$v) {_vm.revocationDescription=$$v},expression:"revocationDescription"}})]}}])})],1)],1),_c('div',{staticClass:"flex space-x-4"},[_c('validation-provider',{attrs:{"name":"Datum der Kündigung","rules":{
          required: !!_vm.revocationCategory,
          date_format: true,
          after: { date: _vm.revocationDateMin, inclusive: true },
          before: { date: _vm.revocationDateMax, inclusive: true },
        },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"booking-cancellation-cancellationDate","type":"date","label":"Datum der Kündigung","wide":"","errors":errors,"required":"","disabled":!_vm.revocationCategory || _vm.revocationCategory.length === 0},model:{value:(_vm.revocationDate),callback:function ($$v) {_vm.revocationDate=$$v},expression:"revocationDate"}})]}}])}),_c('validation-provider',{attrs:{"name":"Datum Studienende","rules":{
          required: !!_vm.revocationCategory,
          date_format: true,
          after: { date: _vm.contractEndDateMin, inclusive: true },
          before: { date: _vm.contractEndDateMax, inclusive: true },
        },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('e-text-field',{attrs:{"id":"booking-cancellation-contractEnd","type":"date","label":"Datum Studienende","wide":"","errors":errors,"required":"","disabled":!_vm.revocationCategory || _vm.revocationCategory.length === 0},model:{value:(_vm.contractEndDate),callback:function ($$v) {_vm.contractEndDate=$$v},expression:"contractEndDate"}})]}}])})],1)]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v("Vertrag widerrufen")]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }