<template>
  <thead>
    <tr class="border-b border-gray-700">
      <th
        v-for="item of headerItems"
        :key="item.label"
        class="pb-4 text-left text-blue-600 first:pl-3 truncate min-w-8"
      >
        <e-tooltip v-if="item.tooltip" :content="item.tooltip">
          <span class="uppercase truncate">{{ item.label }}</span>
        </e-tooltip>
        <template v-else>
          <span class="uppercase truncate">{{ item.label }}</span>
        </template>
      </th>
    </tr>
  </thead>
</template>

<script>
import { ETooltip } from '@careerpartner/nitro';

export default {
  name: 'TableHead',
  components: {
    ETooltip,
  },
  props: {
    headerItems: {
      type: Array,
      required: true,
    },
  },
};
</script>
