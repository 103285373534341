<template>
  <tr class="odd:bg-blue-100 text-left">
    <td class="py-2 pl-3">{{ recognition.total }}</td>
    <td>{{ recognition.work }}</td>
    <td>{{ recognition.study }}</td>
    <td>{{ recognition.abroad }}</td>
    <td>{{ recognition.internal }}</td>
    <td>{{ recognition.external }}</td>
    <td>{{ recognition.retro }}</td>
  </tr>
</template>

<script>
export default {
  name: 'TableRow',
  props: {
    recognition: {
      type: Object,
      required: true,
    },
  },
};
</script>
