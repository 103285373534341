<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-else>
    <toolbar-form id="matriculation-form" @submit="submitMatriculation">
      <template #submit-button>
        <e-icon icon="rocket-launch-outline" class="mr-1" size="xs" />
        <span> {{ matriculationButtonText }} </span>
      </template>
      <template #default>
        <!-- Error message -->
        <div v-show="error.length" class="py-2 text-error">
          <p>
            <e-icon icon="mdi-alert-outline" class="mr-2" />
            {{ readableErrorMessage }}
          </p>
        </div>
        <div class="mt-12 z-100 flex flex-col gap-y-6">
          <div>
            <h3 class="font-bold text-blue-600 uppercase">
              Allgemeine Informationen
            </h3>
            <h1
              class="
                flex
                items-center
                text-xl
                font-bold
                text-blue-600
                uppercase
                gap-x-2
                mt-3
              "
            >
              {{ title }}
              <booking-academic :value="booking.category" />
            </h1>
          </div>

          <div class="w-full flex">
            <div class="w-1/2 flex flex-col h-full pt-6 pb-8 gap-y-16">
              <div class="flex flex-col gap-y-6">
                <e-text-field
                  id="booking-program-title"
                  v-model="matriculation.program.title"
                  disabled
                  label="Studiengang"
                />
                <validation-provider
                  v-slot="{ errors }"
                  name="Curriculumsversion"
                  rules="required"
                >
                  <e-multiselect
                    id="booking-curriculum"
                    v-model="matriculation.program.curriculum"
                    searchable
                    :options="matriculation.availableCurricula"
                    option-label="name"
                    track-by="id"
                    label="Curriculumsversion"
                    :errors="errors"
                    required
                    mapped
                  />
                </validation-provider>
                <div class="flex space-x-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Startdatum"
                    rules="required|date_format"
                    slim
                  >
                    <e-text-field
                      id="booking-matriculation-startDate"
                      v-model="matriculation.startDate"
                      type="date"
                      label="Startdatum"
                      wide
                      :errors="errors"
                      required
                    />
                  </validation-provider>

                  <validation-provider
                    v-if="isTimeModelEnabled"
                    v-slot="{ errors }"
                    name="Zeitmodell"
                    rules="required"
                    slim
                  >
                    <e-multiselect
                      id="booking-matriculation-timeModel"
                      v-model="matriculation.timeModel"
                      searchable
                      :errors="errors"
                      :options="timeModelOptions"
                      track-by="id"
                      option-label="name"
                      label="Zeitmodell"
                      wide
                      mapped
                      required
                    />
                  </validation-provider>
                  <validation-provider
                    v-else
                    v-slot="{ errors }"
                    name="Enddatum"
                    rules="required|date_format"
                    slim
                  >
                    <e-text-field
                      id="booking-matriculation-endDate"
                      v-model="matriculation.endDate"
                      type="date"
                      label="Enddatum"
                      wide
                      disabled
                      :errors="errors"
                      required
                    />
                  </validation-provider>
                </div>

                <validation-provider
                  v-if="isTimeModelChanged"
                  v-slot="{ errors }"
                  name="Neuer Rechnungsbetrag"
                  rules="required|integer|min_value:0"
                >
                  <e-text-field
                    id="time-model-change-course-fee"
                    v-model="matriculation.courseFee"
                    type="number"
                    label="Neuer Rechnungsbetrag"
                    wide
                    :errors="errors"
                    required
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Fachsemester"
                  rules="required|integer|min_value:0"
                >
                  <e-text-field
                    id="booking-previous-related-terms"
                    v-model.number="matriculation.previousRelatedTerms"
                    label="bereits absolvierte Fachsemester"
                    :errors="errors"
                    required
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Hörerstatus"
                  rules="required"
                >
                  <e-multiselect
                    id="booking-listener-status"
                    v-model="matriculation.listenerStatus"
                    searchable
                    :errors="errors"
                    :options="listenerStatusOptions"
                    track-by="id"
                    option-label="name"
                    label="Hörerstatus"
                    disabled
                    mapped
                    required
                  />
                </validation-provider>
                <e-checkbox
                  id="booking-second-study"
                  v-model="matriculation.secondStudy"
                  check-value="false"
                  label="Zweitstudium: graduiertes Studium liegt vor"
                  :options="[true, false]"
                  disabled
                />
              </div>

              <div v-if="!isActiveClickAndStudy" class="flex justify-between">
                <div>
                  <p>Beglaubigung der Hochschulzugangsberechtigung</p>
                  <p
                    v-show="matriculation.qualificationCertifiedOn"
                    class="text-sm text-gray-700"
                  >
                    beglaubigt am {{ certifiedDateFormatted }}
                  </p>
                </div>
                <div>
                  <e-chip
                    :class="chipStatusColor"
                    text-classes="text-white uppercase text-xs font-bold"
                    class="ml-2"
                  >
                    {{
                      matriculation.qualificationCertifiedOn
                        ? 'Beglaubigt'
                        : 'Ausstehend'
                    }}
                  </e-chip>
                </div>
              </div>

              <div v-if="conditions.length > 0" class="flex flex-col gap-y-4">
                <h3 class="font-bold text-blue-600 uppercase">Nachweise</h3>
                <ul class="flex flex-col gap-2">
                  <li v-for="condition in conditions" :key="condition.id">
                    <booking-condition
                      :value="condition"
                      class="flex items-center justify-between gap-4"
                    />
                  </li>
                </ul>
              </div>

              <div
                v-if="admissionCourses.length > 0"
                class="flex flex-col gap-y-4"
              >
                <h3 class="font-bold text-blue-600 uppercase">
                  Zulassungsprogramme
                </h3>
                <ul class="flex flex-col gap-2">
                  <li
                    v-for="(admission, index) in admissionCourses"
                    :key="index"
                  >
                    <admission-display
                      :value="admission"
                      class="flex items-center justify-between gap-4"
                    />
                  </li>
                </ul>
              </div>

              <div v-if="isActiveTrialStudy" class="flex flex-col gap-y-4">
                <h3 class="font-bold text-blue-600 uppercase">Probestudium</h3>
                <e-checkbox
                  id="booking-trial-period"
                  v-model="matriculation.trialPeriod.required"
                  check-value="false"
                  :disabled="bookingHasTrialPeriod"
                  label="Probestudium"
                  :options="[true, false]"
                />
                <div v-if="isTrialStudyRequired" class="flex space-x-4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Dauer Probestudium"
                    rules="required"
                    slim
                  >
                    <e-multiselect
                      id="booking-trialPeriod-duration"
                      v-model="matriculation.trialPeriod.duration"
                      searchable
                      :options="availableTrialPeriodDurations"
                      option-label="name"
                      :disabled="bookingHasTrialPeriod"
                      track-by="duration"
                      label="Dauer Probestudium"
                      :errors="errors"
                      required
                      wide
                      mapped
                    />
                  </validation-provider>
                  <br />
                  <e-text-field
                    id="booking-trialperiod-ects"
                    v-model="matriculation.trialPeriod.ects"
                    disabled
                    rules="required|integer|min_value:0"
                    wide
                    label="Zu erreichende ECTS Anzahl"
                  />
                </div>
              </div>
            </div>
            <div class="bg-image" />
          </div>
        </div>
      </template>
    </toolbar-form>
  </div>
</template>

<script>
import Loader from '@/components/shared/Loader';
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters } from '@/store/modules/booking/types';

import {
  EIcon,
  ECheckbox,
  EChip,
  ETextField,
  EMultiselect,
} from '@careerpartner/nitro';
import { ValidationProvider, extend } from 'vee-validate';
import { required, min_value, integer } from 'vee-validate/dist/rules';
import {
  ACADEMIC_CATEGORY_TYPE,
  API_ENDPOINTS,
  FEATURES,
  LISTENER_STATUS,
  TIME_MODEL_OPTIONS,
  TIME_MODELS,
} from '@/common/constants';
import { Actions as AppActions } from '@/store/modules/app/types';
import dayjs from 'dayjs';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import { isActive, isInactive } from '@/utils/getEnvAndFeatures';
import AdmissionDisplay from '@/components/AdmissionCourses/AdmissionDisplay.vue';
import BookingCondition from '@/components/BookingCondition';

import ToolbarForm from '@/components/ToolbarForm';
import BookingAcademic from '@/components/BookingAcademic';
import { NEXT_MATRICULATION_STATE } from '@/common/selectLists/matriculation';

extend('required', {
  ...required,
  message: 'Dieses Feld muss ausgefüllt sein.',
});
extend('integer', {
  ...integer,
  message: 'Dieses Feld muss eine Nummer sein.',
});
extend('min_value', {
  ...min_value,
  message: 'Dieses Feld darf nicht kleiner als 0 sein.',
});

export default {
  components: {
    Loader,
    EIcon,
    EChip,
    ToolbarForm,
    ECheckbox,
    AdmissionDisplay,
    ETextField,
    EMultiselect,
    ValidationProvider,
    BookingCondition,
    BookingAcademic,
  },

  data() {
    return {
      loading: false,
      error: '',
      waitingForResponse: false,
      matriculation: {
        endDate: '',
        startDate: '',
        program: {
          title: '',
          id: '',
          curriculum: '',
        },
        listenerStatus: '',
        qualificationCertifiedOn: null,
        previousRelatedTerms: 0,
        secondStudy: false,
        trialPeriod: {
          required: false,
          duration: '0',
          ects: 15,
        },
        timeModel: '',
        courseFee: null,
      },
      timeModelOptions: TIME_MODEL_OPTIONS,
    };
  },

  computed: {
    ...mapGetters('booking', {
      booking: Getters.GET_CURRENT_BOOKING,
      matriculationData: Getters.GET_MATRICULATION_DATA,
      admissionCourses: Getters.GET_ADMISSION_COURSES,
      nextMatriculationState: Getters.GET_NEXT_MATRICULALTION_STATE,
      conditions: Getters.GET_CURRENT_CONDITIONS,
    }),
    title() {
      return this.booking?.program?.title;
    }, // TODO: how to check if academic product
    timeModel() {
      return TIME_MODELS[this.booking?.timeModel] || TIME_MODELS.unknown;
    },
    isTimeModelEnabled() {
      return isActive(FEATURES.TIME_MODEL);
    },
    isTimeModelChanged() {
      return (
        isActive(FEATURES.TIME_MODEL) &&
        (TIME_MODELS[this.booking?.timeModel] ?? TIME_MODELS.unknown) !==
          (TIME_MODELS[this.matriculation.timeModel] ?? TIME_MODELS.unknown)
      );
    },
    isAcademicProgram() {
      return this.booking.category === ACADEMIC_CATEGORY_TYPE.academic;
    },
    isActiveTrialStudy() {
      return isInactive(FEATURES.TRIAL_PERIOD) === false;
    },
    bookingHasTrialPeriod() {
      return !!this.booking.trialPeriod;
    },
    isActiveClickAndStudy() {
      return isInactive(FEATURES.CLICK_AND_STUDY) === false;
    },
    isTrialStudyRequired() {
      if (!this.matriculation.trialPeriod) {
        return false;
      }

      return this.matriculation.trialPeriod.required;
    },
    certifiedDateFormatted() {
      if (this.matriculation.qualificationCertifiedOn) {
        return dayjs(this.matriculation.qualificationCertifiedOn).format(
          'DD.MM.YYYY',
        );
      }
      return '';
    },
    listenerStatusOptions() {
      const dropDownValues = [];
      for (const [key, value] of Object.entries(LISTENER_STATUS)) {
        dropDownValues.push({ id: key, name: value });
      }

      return dropDownValues;
    },
    chipStatusColor() {
      if (this.matriculation.qualificationCertifiedOn) {
        return 'bg-green-600';
      } else {
        return 'bg-orange-600';
      }
    },
    availableTrialPeriodDurations() {
      return [
        {
          duration: 12,
          name: '12 Monate',
        },
        {
          duration: 18,
          name: '18 Monate',
        },
        {
          duration: 24,
          name: '24 Monate',
        },
      ];
    },
    matriculationButtonText() {
      const predicates = {
        [NEXT_MATRICULATION_STATE.MATRICULATABLE]: 'Immatrikulieren',
        [NEXT_MATRICULATION_STATE.ENROLABLE]: 'Immatrikulieren unter Vorbehalt',
        [NEXT_MATRICULATION_STATE.NONE]: 'Nicht immatrikulierbar',
      };
      return (
        predicates[this.nextMatriculationState] ?? 'Nicht immatrikulierbar'
      );
    },
    readableErrorMessage() {
      return this.error;
    },
  },

  async beforeMount() {
    try {
      this.loading = true;
      await this.loadBooking(this.$route.params.bookingId);
      await this.getMatriculation();

      // to visually showcase the already existing trial period duration
      if (this.bookingHasTrialPeriod) {
        this.matriculation.trialPeriod.required = true;
        this.matriculation.trialPeriod.duration =
          this.booking.trialPeriod.duration;
      }
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions('booking', {
      loadBooking: Actions.LOAD_SINGLE_BOOKING,
    }),
    async getMatriculation() {
      const data = this.matriculationData;
      this.matriculation = {
        ...data,
        program: {
          ...data.program,
          curriculum: data.curriculum,
        },
        previousRelatedTerms: 0,
        trialPeriod: {
          required: false,
          duration: '',
          ects: 15,
        },
        timeModel: this.booking.timeModel,
      };
    },
    async submitMatriculation() {
      try {
        this.waitingForResponse = true;

        const matriculationUrl = API_ENDPOINTS.MATRICULATION.replace(
          ':bookingId',
          this.$route.params.bookingId,
        );

        let trialPeriod = null;
        if (this.matriculation.trialPeriod.required) {
          trialPeriod = {
            duration: parseInt(this.matriculation.trialPeriod.duration),
          };
        }

        const matriculationPayload = {
          program: {
            id: this.matriculation.program.id,
            curriculum: this.matriculation.program.curriculum,
          },
          startDate: this.matriculation.startDate,
          endDate: this.matriculation.endDate,
          previousRelatedTerms: this.matriculation.previousRelatedTerms,
          timeModel: this.isTimeModelChanged
            ? this.matriculation.timeModel
            : null,
          courseFee: parseInt(this.matriculation.courseFee),
        };

        // only add trial period, if it does not already exists in the booking
        if (!this.bookingHasTrialPeriod) {
          matriculationPayload.trialPeriod = trialPeriod;
        }

        await ApiServiceWrapper.post(matriculationUrl, matriculationPayload);

        let message = 'Immatrikulation in Studiengang war erfolgreich.';
        if (!this.matriculation.qualificationCertifiedOn) {
          message =
            'Immatrikulation unter Vorbehalt im Studiengang war erfolgreich.';
        }

        this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, { message });
        this.$router.push({ path: '..', append: true });
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.bg-image {
  background-image: url('https://res.cloudinary.com/iubh/image/upload/v1664368523/IT%20-%20Epos%20/illustrations/epos-booking-matriculation.svg');
  @apply w-1/2 bg-center bg-no-repeat bg-contain h-auto;
}
</style>
