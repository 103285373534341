<template>
  <div>
    <div class="inline-flex">
      <dt>Urlaub:</dt>
      <dd class="text-gray-700 ml-1">
        {{ content }}
      </dd>
    </div>
    <div class="inline-flex">
      <dt>{{ reason }}</dt>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefinitionItem',
  props: {
    content: {
      type: String,
      required: true,
    },
    reason: {
      type: String,
      required: true,
    },
  },
};
</script>
