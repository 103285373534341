<template>
  <div>
    <h3 class="font-bold text-blue-600 uppercase pb-7">Allgemeine Informationen</h3>
    <e-card outlined rounded sheet>
      <div class="flex w-full justify-between p-4">
        <div>
          <overview-information :item="booking" />
        </div>
        <dl v-if="booking.graduation && booking.graduation.name" class="mt-9">
          <definition-item headline="Abschlussziel" :content="booking.graduation.name" />
        </dl>
        <div class="flex flex-col border-l border-gray-300">
          <div class="pl-4">
            <Status :status="booking.status" :trial-period="booking.trialPeriod" :start-date="booking.startDate"
              :cancellation="booking.cancellationDetails" :graduation-details="booking.graduationDetails"
              :conditions="booking.conditions" outlined />
            <e-button v-if="!isProduction" variant="text" @click="goToMetabaseDashboard">
              BUCHUNGS-AKTIVITÄTEN ANZEIGEN
              <e-icon icon="open-in-new" />
            </e-button>
          </div>
        </div>
      </div>
    </e-card>
  </div>
</template>

<script>
import OverviewInformation from '@/components/shared/OverviewInformation';
import DefinitionItem from '@/components/shared/DefinitionItem';
import Status from '@/components/shared/Status/Status';
import { EButton, ECard, EIcon } from '@careerpartner/nitro';
import {
  Actions as BookingActions
} from '@/store/modules/booking/types';
import { Actions as AppActions } from '@/store/modules/app/types';
import { Environment } from '@/common/constants';
import { mapState } from 'vuex';

export default {
  name: 'GeneralBookingDetails',
  components: {
    OverviewInformation,
    DefinitionItem,
    Status,
    EButton,
    ECard,
    EIcon
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      environment: (state) => state.app.environment,
    }),
    isProduction() {
      return this.environment === Environment.PROD;
    },
  },
  methods: {
    goToMetabaseDashboard() {
      if (!this.environment) {
        this.$emit(`app/${AppActions.SET_ERROR}`, 'Environment could not be determined.');
        return;
      }

      const profileId = this.$route.params.id;
      const bookingId = this.$route.params.bookingId;

      try {
        this.$store.dispatch(`booking/${BookingActions.GO_TO_METABASE_BOOKING_TABLE}`, { env: this.environment, profileId, bookingId });
      } catch (error) {
        this.$emit(`app/${AppActions.SET_ERROR}`, `Das Buchungsaktivitäts-Dashboard konnte nicht geöffnet werden. Bitte versuchen Sie es später noch einmal. ${error}`);
      }
    },
  }
};
</script>
