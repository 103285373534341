export const Mutations = {
  CLEAR_ERRORS: 'clearErrors',
  DECREMENT_LOADING: 'decrementLoading',
  INCREMENT_LOADING: 'incrementLoading',
  PUSH_TO_ERROR_STACK: 'pushToErrorStack',
  REMOVE_ERROR_FROM_STACK: 'removeErrorFromStack',
  UPDATE_SNACKBAR: 'updateSnackbar',
  SET_ENVIRONMENT: 'setEnvironment'
};

export const Actions = {
  DISMISS_ERROR: 'dismissError',
  SET_ERROR: 'setError',
  UPDATE_SNACKBAR: 'updateSnackbar',
};

export const Getters = {
  CURRENT_ERROR: 'currentError',
  HAS_ERRORS: 'hasErrors',
  LOADING: 'loading',
  SNACKBAR: 'snackbar',
};
