<template>
  <process-modal-form
    :id="id"
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitRecall"
  >
    <template #form-content>
      <validation-provider
        v-slot="{ errors }"
        :name="dateNaming"
        :rules="{
          required: true,
          date_format: true,
          after: { date: recallDateMin, inclusive: true },
          before: { date: recallDateMax, inclusive: true },
        }"
        slim
        immediate
      >
        <e-text-field
          id="booking-cancellation-recall-date"
          v-model="recallDate"
          type="date"
          :label="dateNaming"
          wide
          :errors="errors"
          required
        />
      </validation-provider>
    </template>
    <template #submit-button-label> Speichern </template>
  </process-modal-form>
</template>

<script>
import { ETextField } from '@careerpartner/nitro';
import { ValidationProvider, extend } from 'vee-validate';
import { API_ENDPOINTS, DATE_FORMAT } from '@/common/constants';
import { required } from 'vee-validate/dist/rules';
import dayjs from 'dayjs';
import { Actions as AppActions } from '@/store/modules/app/types';
import { CANCEL_REASON_KEYS } from '@/common/selectLists/cancellationReasons';
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';

extend('required', {
  ...required,
  message: 'Dieses Feld muss ausgefüllt sein.',
});

export default {
  name: 'RecallModal',
  components: {
    ProcessModalForm,
    ETextField,
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  emits: ['recall-successful'],
  data() {
    return {
      error: '',
      recallDate: '',
      recallDateMin: '',
      recallDateMax: '',
      waitingForResponse: false,
    };
  },
  computed: {
    reason() {
      return this.booking.cancellationDetails.reason;
    },
    dateNaming() {
      return this.reason === CANCEL_REASON_KEYS.REGULAR
        ? 'Datum Rückzug Kündigung'
        : 'Datum Rückzug Widerruf';
    },
    cancellationDate() {
      return dayjs(this.booking.cancellationDetails.cancellationDate);
    },
  },
  beforeMount() {
    this.setDates();
  },
  beforeUnmount() {
    this.error = '';
  },
  methods: {
    async submitRecall() {
      this.waitingForResponse = true;

      const recallUrl = API_ENDPOINTS.RECALL.replace(':bookingId', this.$route.params.bookingId);

      const recallPayload = {
        cancellationRecallDate: this.recallDate,
      };

      try {
        await ApiServiceWrapper.post(recallUrl, recallPayload);

        const message =
          this.reason === CANCEL_REASON_KEYS.REGULAR
            ? 'Kündigung erfolgreich zurückgezogen'
            : 'Widerruf erfolgreich zurückgezogen';

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message,
        });

        this.$emit('recall-successful');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
    setDates() {
      this.recallDate = dayjs().format(DATE_FORMAT).toString();
      // replace with created Date
      this.recallDateMin = dayjs(this.cancellationDate).subtract(1, 'month').format(DATE_FORMAT);

      if (this.reason === CANCEL_REASON_KEYS.REGULAR) {
        this.recallDateMax = dayjs(this.booking.endDate).format(DATE_FORMAT);
      } else {
        this.recallDateMax = dayjs(this.booking.status.revocationDeadline).format(DATE_FORMAT);
      }
    },
  },
};
</script>
