<template>
  <validation-observer :ref="refId" v-slot="{ invalid }" :vid="refId" slim>
    <form @submit.prevent="(e) => onSubmit(e)">
      <e-toolbar>
        <div class="flex space-x-8">
          <e-button :disabled="invalid" action-type="submit" variant="text" small>
            <slot name="submit-button">
              <e-icon icon="content-save-outline" class="mr-1" size="xs" />
              <span> Absenden </span>
            </slot>
          </e-button>
        </div>
      </e-toolbar>

      <slot v-bind="{ invalid }" />

      <p class="text-xs text-gray-700">* Pflichtfelder</p>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { EToolbar, EButton, EIcon } from '@careerpartner/nitro';

export default {
  components: {
    ValidationObserver,
    EToolbar,
    EButton,
    EIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  emits: ['submit'],
  computed: {
    refId() {
      return `${this.id}-form`;
    },
  },
  methods: {
    async onSubmit(event) {
      const isValid = await this.$refs[this.refId].validate();
      if (!isValid) {
        return;
      }
      this.$emit('submit', event);
    },
  },
};
</script>
