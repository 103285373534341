import { Getters } from './types';
import { OWNING_SYSTEM } from '@/common/constants';

export default {
  [Getters.FULL_NAME](state) {
    const birthname = state.profile.person.birthName
      ? `(${state.profile.person.birthName})`
      : '';

    const givenName = state.profile.person.givenName || ' ';
    const familyName = state.profile.person.familyName || ' ';

    return `${givenName} ${familyName} ${birthname}`;
  },
  [Getters.PROFILE_DAM_FOLDER_URL](state) {
    return state.profile.damFolderUrl;
  },
  [Getters.PROFILE_ID](state) {
    return state.profile.id;
  },
  [Getters.PROFILE_ACCOMODATION](state) {
    return state.profileAccomodation;
  },
  [Getters.PROFILE_ACCOMODATION_ACTIVE](state) {
    return state.profileAccomodation !== null;
  },
  [Getters.PROFILE_ACADEMY_ID](state) {
    return state.profile?.academyId;
  },
  [Getters.PROFILE](state) {
    return state.profile;
  },
  [Getters.IS_PROFILE_OWNED_BY_CARE](state) {
    return state.profileOwnership.ownership === OWNING_SYSTEM.CARE;
  },
};
