<template>
  <div>
    <div class="grid grid-cols-12 mt-20">
      <div class="col-span-6">
        <h3 class="font-bold text-blue-600 uppercase">Übersicht Anerkennung</h3>
      </div>
      <div
        v-if="isEnroled(booking.status.name) && isProfileOwnedByCARE === false"
        class="col-span-6 justify-end flex pr-2"
      >
        <e-button variant="text" small @click="showRecognitionModal">
          <e-icon icon="mdi-plus" />
          Fachsemester anerkennen
        </e-button>
      </div>
    </div>
    <table class="mt-6 table-auto w-full">
      <TableHead :header-items="header" />
      <tbody>
        <TableRow :recognition="booking.recognitionHistory" />
      </tbody>
    </table>
    <div
      v-if="!booking.recognitionHistory"
      class="flex flex-col items-center w-full mt-10 text-center"
    >
      <img :src="emptyTableImg" alt="" />
      <p class="mt-4 font-bold">Noch keine Daten vorhanden</p>
      <p v-if="booking.status.name" class="max-w-xs mt-4">
        Die Übersicht wird angelegt, sobald die Immatrikulation abgeschlossen
        ist.
      </p>
    </div>
  </div>
</template>

<script>
import TableHead from '@/components/RecognitionHistory/TableHead';
import TableRow from '@/components/RecognitionHistory/TableRow';
import { EButton, EIcon } from '@careerpartner/nitro';
import { EDIT_BOOKING_EVENTS } from '@/common/events/editBookingEvents';
import { ENROLMENT_STATUS_STRINGS } from '@/common/constants';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';

export default {
  name: 'RecognitionHistory',
  components: {
    TableRow,
    TableHead,
    EIcon,
    EButton,

  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  emits: ['show-recognition-modal' , 'input'],
  data() {
    return {
      emptyTableImg:
        'https://res.cloudinary.com/iubh/image/upload/v1643799766/IT%20-%20Epos%20/illustrations/epos-booking-no-data-semestereinsicht.svg',
      header: [
        {
          label: 'Summe ECTS',
          tooltip: undefined,
        },
        {
          label: 'Berufserfahrung',
          tooltip: undefined,
        },
        {
          label: 'Studienleistung',
          tooltip: undefined,
        },
        {
          label: 'Ausland',
          tooltip: undefined,
        },
        {
          label: 'Intern',
          tooltip: undefined,
        },
        {
          label: 'Extern',
          tooltip: undefined,
        },
        {
          label: 'Nachträglich',
          tooltip: undefined,
        },
      ],
    };
  },
  computed: {
    isProfileOwnedByCARE() {
      return this.$store.getters[`profile/${ProfileGetters.IS_PROFILE_OWNED_BY_CARE}`];
    },
  },
  methods: {
    showRecognitionModal() {
      this.$emit('show-recognition-modal', EDIT_BOOKING_EVENTS.ON_ADD_REVOCATION);
    },
    isEnroled(bookingStatus) {
      return [
        ENROLMENT_STATUS_STRINGS.ENROLED,
        ENROLMENT_STATUS_STRINGS.ENROLED_WITH_CONDITIONS,
        ENROLMENT_STATUS_STRINGS.IN_ADMISSION,
        ENROLMENT_STATUS_STRINGS.STUDYING,
        ENROLMENT_STATUS_STRINGS.STUDYING_WITH_CONDITIONS,
      ].includes(bookingStatus);
    },
  },
};
</script>
