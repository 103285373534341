<template>
  <e-infobanner
    v-if="hasErrors"
    variant="error"
    style="top: 50px"
    class="sticky z-90"
  >
    <template #message>
      {{ currentError.message }}
    </template>
    <template #actions>
      <e-button variant="blank" @click="reportError"
        ><span class="text-white hover:underline uppercase"
          >Melden</span
        ></e-button
      >
      <e-button variant="blank" class="ml-4" @click="reloadPage"
        ><span class="text-white hover:underline uppercase"
          >Neu laden</span
        ></e-button
      >
      <e-button variant="blank" class="ml-4" @click="dismissError"
        ><span class="text-white"><e-icon icon="close" /></span
      ></e-button>
    </template>
  </e-infobanner>
</template>

<script>
import { EInfobanner, EButton, EIcon } from '@careerpartner/nitro';
import { mapActions, mapGetters } from 'vuex';
import {
  Getters as AppGetters,
  Actions as AppActions,
} from '@/store/modules/app/types';
import { Sentry } from '@/plugins/sentry';
import { authMiddleware } from '@epos/core-applets';

export default {
  name: 'ErrorBanner',
  components: {
    EButton,
    EIcon,
    EInfobanner,
  },
  computed: {
    ...mapGetters('app', {
      hasErrors: AppGetters.HAS_ERRORS,
      currentError: AppGetters.CURRENT_ERROR,
    }),
  },
  methods: {
    ...mapActions({
      dismissError: `app/${AppActions.DISMISS_ERROR}`,
    }),
    reloadPage() {
      location.reload();
    },
    reportError() {
      const { name, email } = authMiddleware.isAuthenticated
        ? authMiddleware.user
        : { name: '', email: '' };

      Sentry.captureException(this.currentError);
      Sentry.showReportDialog({
        user: { name, email },
      });
    },
  },
};
</script>
