<template>
  <e-menu :actions="items" @menu-button-clicked="handleMenuButtonClicked">
    <template #activator="{ interact }">
      <e-button
        variant="text"
        small
        :data-test="`${id}-button`"
        :disabled="allItemsDisabled || disabled"
        @click="interact"
      >
        <e-icon :icon="icon" class="mr-1" size="xs" />
        {{ label }}
        <e-icon icon="menu-down"
      /></e-button>
    </template>
  </e-menu>
</template>

<script>
import { EButton, EIcon, EMenu } from '@careerpartner/nitro';

export default {
  name: 'ButtonWithMenuFactory',
  components: { EButton, EIcon, EMenu },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'edit-square-outline',
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['menu-button-clicked'],
  computed: {
    allItemsDisabled() {
      return this.items.every((item) => item.disabled);
    },
  },
  methods: {
    handleMenuButtonClicked(e) {
      this.$emit('menu-button-clicked', e);
    },
  },
};
</script>
