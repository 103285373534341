import { Actions, Mutations } from './types';

export default {
  [Actions.SET_ERROR]({ commit }, error) {
    commit(Mutations.PUSH_TO_ERROR_STACK, error);
  },
  [Actions.DISMISS_ERROR]({ commit }) {
    commit(Mutations.REMOVE_ERROR_FROM_STACK);
  },
  [Actions.UPDATE_SNACKBAR]({ commit }, payload) {
    commit(Mutations.UPDATE_SNACKBAR, payload);
  },
};
