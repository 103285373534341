<template>
  <e-applet-wrapper id="epos-booking" name="EPOS Customer Booking">
    <template #viewnavigation>
      <e-view-navigation id="view-navigation" :links="links">
        <template #headline>
          {{ viewNavigationLabel.headline }}
        </template>
        <template #subheadline>
          {{ viewNavigationLabel.subHeadline }}
        </template>
      </e-view-navigation>
    </template>
    <template #appletcontent>
      <router-view id="view"/>
      <Snackbar />
    </template>
  </e-applet-wrapper>
</template>

<script>
import { makeCustomerServiceTabs } from '@epos/core-applets';
import { EAppletWrapper, EViewNavigation } from '@careerpartner/nitro';
import { mapGetters } from 'vuex';
import { Getters as AppGetters } from '@/store/modules/app/types';
import { Actions as CurrentUserActions } from '@/store/modules/currentUser';
import {
  Actions as ProfileActions,
  Getters as ProfileGetters,
} from '@/store/modules/profile/types';
import { PAGE_TITLE_TEMPLATE } from '@/common/constants';
import Snackbar from '@/components/shared/Snackbar';

export default {
  name: 'AppBooking',
  components: { Snackbar, EAppletWrapper, EViewNavigation },
  metaInfo() {
    return {
      title: `${this.profileName} - Buchungen`,
      titleTemplate: `%s ${PAGE_TITLE_TEMPLATE}`,
    };
  },
  computed: {
    ...mapGetters({
      profileName: `profile/${ProfileGetters.FULL_NAME}`,
      damFolderUrl: `profile/${ProfileGetters.PROFILE_DAM_FOLDER_URL}`,
      loading: `app/${AppGetters.LOADING}`,
    }),
    viewNavigationLabel() {
      return {
        headline: this.$route.meta.headline || '...',
        subHeadline: this.$route.meta.subheadline || this.profileName || '...',
      };
    },
    links() {
      return makeCustomerServiceTabs(this.$route.params.id, this.damFolderUrl);
    },
  },
  async beforeMount() {
    await this.$store.dispatch(
      `currentUser/${CurrentUserActions.SET_CURRENT_USER}`,
    );
    await this.$store.dispatch(
      `currentUser/${CurrentUserActions.DECODE_TOKEN}`,
    );
    await this.$store.dispatch(
      `profile/${ProfileActions.LOAD_PROFILE}`,
      this.$route.params.id,
    );
    await this.$store.dispatch(
      `profile/${ProfileActions.LOAD_PROFILE_ACCOMODATION}`,
      this.$route.params.id,
    );
    await this.$store.dispatch(
      `profile/${ProfileActions.LOAD_PROFILE_OWNERSHIP}`,
      this.$route.params.id,
    );
  },
};
</script>

<!--
  Calendar icon picker not shown in chrome, this is done via ETextField, overwrite it here for the whole app
  Referenced ticket: https://careerpartner.atlassian.net/browse/EP-11477
-->
<style>
input[type='date']::-webkit-calendar-picker-indicator {
  display: initial;
  appearance: initial;
}
</style>
