<template>
  <div class="w-full flex flex-col pt-4 items-center">
    <e-simple-loader />
    <p class="text-xs text-cta">Wird geladen...</p>
  </div>
</template>

<script>
import { ESimpleLoader } from '@careerpartner/nitro';

export default {
  name: 'Loader',
  components: {
    ESimpleLoader,
  },
};
</script>
