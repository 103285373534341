<template>
  <e-toolbar>
    <div class="flex space-x-8">
      <matriculate-or-enrol-button @menu-button-clicked="handleMenuButtonClicked" />
      <admission-program-button
        :disabled="isProfileOwnedByCARE || hasAdmissionCourse"
        @menu-button-clicked="handleMenuButtonClicked"
      />

      <button-with-menu-factory
        id="trialPeriod"
        label="Probestudium"
        icon="timer-settings-outline"
        :disabled="isTrialPeriodDisabled || isProfileOwnedByCARE"
        :items="trialPeriodMenuItems"
        @menu-button-clicked="handleMenuButtonClicked"
      />

      <button-with-menu-factory
        id="editBooking"
        label="Buchung bearbeiten"
        icon="square-edit-outline"
        :disabled="isProfileOwnedByCARE"
        :items="editBookingMenuItems"
        @menu-button-clicked="handleMenuButtonClicked"
      />

      <button-with-menu-factory
        id="endBooking"
        label="Buchung beenden"
        icon="bank-off-outline"
        :disabled="isEndingBookingDisabled || isProfileOwnedByCARE"
        :items="endBookingMenuItems"
        @menu-button-clicked="handleMenuButtonClicked"
      />

      <button-with-menu-factory
        id="editContact"
        label="Vertragsänderungen"
        icon="file-sign"
        :disabled="isProfileOwnedByCARE"
        :items="editContractMenuItems"
        @menu-button-clicked="handleMenuButtonClicked"
      />
      <e-button
        v-if="isEnabledDocumentDownload"
        variant="text"
        small
        data-test="activate-profile-accomodation-button"
        @click="handleMenuButtonClicked(DOCUMENT_EVENTS.ON_SHOW_DOCUMENT_DOWNLOAD)"
      >
        <e-icon icon="mdi-file-document-outline" class="mr-1" size="xs" />
        Dok Download
      </e-button>
      <e-button
        v-if="isResignateOwnershipButtonEnabled"
        variant="text"
        small
        :disabled="false"
        @click="handleMenuButtonClicked(resignOwnership)"
      >
        In CARE bearbeiten
      </e-button>
    </div>
  </e-toolbar>
</template>

<script>
import { mapGetters } from 'vuex';
import { Getters as BookingGetters } from '@/store/modules/booking/types';
import { EToolbar, EButton, EIcon } from '@careerpartner/nitro';
import AdmissionProgramButton from './AdmissionProgramButton';
import MatriculateOrEnrolButton from './MatriculateOrEnrolButton';
import trialPeriodMenuItems from '@/utils/TrialStudyMenu/trialPeriodMenuItems';
import ButtonWithMenuFactory from '@/components/BookingDetailToolbar/shared/ButtonWithMenuFactory';
import editBookingMenuItems from '@/utils/EditBookingMenu/editBookingMenuItems';
import endBookingMenuItems from '@/utils/EndBookingMenu/endBookingMenuItems';
import editContractMenuItems from '@/utils/EditContractMenu/editContractMenuItems';
import dayjs from 'dayjs';
import { DOCUMENT_EVENTS } from '@/common/events/documentEvents';
import { OWNERSHIP_EVENTS } from '@/common/events/ownershipEvents';
import { API_ENDPOINTS, FEATURES, PROGRAM_CHANGE_REQUEST_STATUS } from '@/common/constants';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';

export default {
  name: 'BookingDetailToolbar',
  components: {
    ButtonWithMenuFactory,
    AdmissionProgramButton,
    EToolbar,
    EButton,
    EIcon,
    MatriculateOrEnrolButton,
  },
  emits: ['toolbar-action'],
  data() {
    return {
      DOCUMENT_EVENTS,
      hasPcr: false,
      bookingHasRevocableTmcrs: false,
    };
  },
  computed: {
    ...mapGetters('booking', { booking: BookingGetters.GET_CURRENT_BOOKING }),
    isEndingBookingDisabled() {
      return this.$isInactive(FEATURES.BOOKING_COMPLETION);
    },
    isEnabledDocumentDownload() {
      return this.$isActive(FEATURES.DOCUMENT_DOWNLOAD);
    },
    isTrialPeriodDisabled() {
      return this.$isInactive(FEATURES.TRIAL_PERIOD);
    },
    isResignateOwnershipButtonEnabled() {
      return this.$isActive(FEATURES.SEPERATE_PROFILE_OWNERSHIP) && !this.isProfileOwnedByCARE;
    },
    editBookingMenuItems() {
      return editBookingMenuItems(this.booking, this.hasPcr, this.bookingHasRevocableTmcrs);
    },
    editContractMenuItems() {
      return editContractMenuItems(
        dayjs(this.booking.startDate),
        this.booking.status.name,
        dayjs(this.booking.status.revocationDeadline)
      );
    },
    endBookingMenuItems() {
      return endBookingMenuItems();
    },
    trialPeriodMenuItems() {
      return trialPeriodMenuItems();
    },
    id() {
      return this.$route.params.id;
    },
    resignOwnership() {
      return OWNERSHIP_EVENTS.ON_RESIGNATE_OWNERSHIP;
    },
    isProfileOwnedByCARE() {
      return this.$store.getters[`profile/${ProfileGetters.IS_PROFILE_OWNED_BY_CARE}`];
    },
    hasAdmissionCourse() {  
      return this.booking?.admissionCourses?.length > 0;
    },
  },
  async beforeMount() {
    await this.loadPcrByNewBookingId();
    await this.loadTmcrsByBookingId();
  },
  methods: {
    handleMenuButtonClicked(e) {
      this.$emit('toolbar-action', e);
    },
    async loadPcrByNewBookingId() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.GET_PROGRAM_CHANGE_REQUESTS.replace(
        ':bookingId',
        this.$route.params.bookingId
      );

      try {
        const {
          data: { 0: pcr },
        } = await ApiServiceWrapper.get(requestUrl);

        this.hasPcr = [
          PROGRAM_CHANGE_REQUEST_STATUS.APPLIED,
          PROGRAM_CHANGE_REQUEST_STATUS.PENDING,
        ].includes(pcr.status);
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
    async loadTmcrsByBookingId() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.GET_TIME_MODEL_CHANGE_REQUESTS.replace(
        ':bookingId',
        this.$route.params.bookingId
      );

      try {
        const { data: tmcrs } = await ApiServiceWrapper.get(requestUrl);
        this.bookingHasRevocableTmcrs = tmcrs.some((tmcr) => tmcr.isRevocable);
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
