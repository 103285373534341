export const INTERRUPTION_REASONS = [
  { label: 'Krankheit', key: 'illness' },
  { label: 'Mutterschutz/ Elternzeit', key: 'parenting_leave' },
  { label: 'Praktikum', key: 'internship' },
  { label: 'Auslandsaufenthalt', key: 'stay_abroad' },
  { label: 'Sonstige Gründe', key: 'others' },
];

export const INTERRUPTION_LENGTH = [
  { label: '6 Monate', key: '6' },
  { label: '12 Monate', key: '12' },
];

export const RECALL_INTERRUPTION_REASONS = [
  { label: 'Widerruf der Beurlaubung von Seite des Kunden', key: 'revocation' },
  { label: 'Korrektur', key: 'manual_correction' },
];

export const RECALL_INTERRUPTION_KEYS = {
  REVOCATION: 'revocation',
  MANUAL_CORRECTION: 'manual_correction',
};
