<template>
  <div>
    <div>
      <h3 class="uppercase text-blue-600 font-bold mt-20">Zulassungsprogramme</h3>

      <table class="w-full mt-6 table-auto">
        <TableHead :header-items="header" />
        <tbody>
          <TableRow
            v-for="item in admissionCourses"
            :key="item.id"
            :item="item"
            :is-editable="isProfileOwnedByCARE === false"
            :is-status-change-enabled="admissionStatusChangeEnabled"
            @edit-admission="editAdmission"
            @delete-admission="deleteAdmission"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TableHead from '@/components/AdmissionCourses/TableHead';
import TableRow from '@/components/AdmissionCourses/TableRow';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';
import { FEATURES } from '@/common/constants';

export default {
  name: 'AdmissionCourses',
  components: {
    TableHead,
    TableRow,
  },
  props: {
    admissionCourses: {
      type: Array,
      default: () => [],
    },
    showEditModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit-admission', 'delete-admission'],
  data() {
    return {
      header: [
        { label: 'Name' },
        { label: 'Curriculumsversion' },
        { label: 'Start' },
        { label: 'Fristende' },
        { label: 'Status' },
        { label: '' },
      ],
    };
  },
  computed: {
    isProfileOwnedByCARE() {
      return this.$store.getters[`profile/${ProfileGetters.IS_PROFILE_OWNED_BY_CARE}`];
    },
    admissionStatusChangeEnabled() {
      return this.$isActive(FEATURES.ADMISSION_STATUS_CHANGE);
    },
  },
  methods: {
    editAdmission(e) {
      this.$emit('edit-admission', e);
    },
    deleteAdmission(e) {
      this.$emit('delete-admission', e);
    },
  },
};
</script>
