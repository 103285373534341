<template>
  <div>
    <ul>
      <li v-for="(binding, index) in bindings" :key="index">
        <binding-param
          :id="index"
          :value="values[index]"
          :values="bindings"
          :binding="binding"
          @input="(v) => updateValue(index, v)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import BindingParam from '@/components/Document/Bindings/Param';

export default {
  name: 'Bindings',

  components: {
    BindingParam,
  },

  props: {
    bindings: { type: Array, default: () => [] },
  },

  emits: ['input'],

  data() {
    return {
      values: [],
    };
  },

  watch: {
    bindings(bindings) {
      this.values = new Array(bindings.length);
    },

    values(values) {
      const newValues = this.bindings.reduce((acc, binding, index) => {
        const bindingValues = acc[binding.bindingName] ?? {};
        return {
          ...acc,
          [binding.bindingName]: {
            ...bindingValues,
            [binding.name]: values[index],
          },
        };
      }, {});
      this.$emit('input', newValues);
    },
  },
  methods: {
    updateValue(index, value) {
      this.$set(this.values, index, value);
    },
  },
};
</script>
