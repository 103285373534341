<template>
  <e-chip :key="status.id" :class="status.class" class="m-2">
    <e-icon :icon="status.icon" size="xs" />
    {{ status.text }}
    <slot name="additionalContent"></slot>
  </e-chip>
</template>

<script>
import { ADMISSION_PROGRAM_STATUS, FEATURES } from '@/common/constants';
import { EChip, EIcon } from '@careerpartner/nitro';
import { isInactive } from '@/utils/getEnvAndFeatures';

export const allAdmissionStatus = [
  {
    id: ADMISSION_PROGRAM_STATUS.IN_PROGRESS,
    icon: 'bank-outline',
    text: 'in Durchführung',
  },
  {
    id: ADMISSION_PROGRAM_STATUS.RESULTS_PENDING,
    icon: 'timer-sand',
    text: 'Ergebnis ausstehend',
  },

  {
    id: ADMISSION_PROGRAM_STATUS.PASSED,
    icon: 'check',
    text: 'Bestanden',
  },

  {
    id: ADMISSION_PROGRAM_STATUS.FAILED,
    icon: 'close',
    text: 'Endgültig nicht bestanden',
  },
];

const statusFeatureFlags = {
  [ADMISSION_PROGRAM_STATUS.RESULTS_PENDING]: FEATURES.ADMISSION_STATUS_EXAM_ATTENDED,
  [ADMISSION_PROGRAM_STATUS.PASSED]: FEATURES.ADMISSION_STATUS_GRADUATED,
};

const statusStates = {
  [ADMISSION_PROGRAM_STATUS.IN_PROGRESS]: {
    active: 'text-white bg-gray-600 hover:bg-gray-800 group-hover:bg-gray-800',
    disabled: 'text-white bg-gray-300 cursor-not-allowed',
  },
  [ADMISSION_PROGRAM_STATUS.RESULTS_PENDING]: {
    active: 'text-black bg-status-yellow hover:bg-yellow-600 group-hover:bg-yellow-600',
    disabled: 'text-white bg-gray-300 cursor-not-allowed',
  },
  [ADMISSION_PROGRAM_STATUS.PASSED]: {
    active: 'text-white bg-green-600 hover:bg-green-700 group-hover:bg-green-700',
    disabled: 'text-white bg-gray-300 cursor-not-allowed',
  },
  [ADMISSION_PROGRAM_STATUS.FAILED]: {
    active: 'text-white bg-status-red hover:bg-red-600 group-hover:bg-red-600',
    disabled: 'text-white bg-gray-300 cursor-not-allowed',
  },
};

export default {
  name: 'AdmissionStatus',

  components: {
    EChip,
    EIcon,
  },

  props: {
    value: { type: String, default: undefined },
    active: { type: Boolean, default: false },
  },
  computed: {
    status() {
      const status = allAdmissionStatus.find((s) => s.id === this.value);

      if (
        !status ||
        (isInactive(statusFeatureFlags[status.id]) &&
          [ADMISSION_PROGRAM_STATUS.RESULTS_PENDING, ADMISSION_PROGRAM_STATUS.PASSED].includes(
            status
          ))
      ) {
        return {
          id: status.id,
          icon: status.icon,
          text: status.text,
          class: statusStates[this.value]['disabled'],
        };
      }

      status['class'] = statusStates[this.value][!this.active ? 'disabled' : 'active'];

      return status;
    },
  },
};
</script>
