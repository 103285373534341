<template>
  <Loader v-if="loading" />
  <process-modal-form
    v-else
    class="w-full"
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitProgramChangeRequest"
  >
    <template #form-content>
      <e-section headline="">
        <validation-provider
          v-if="availablePrograms"
          v-slot="{ errors }"
          name="Studiengang"
          rules="required"
          slim
        >
          <e-multiselect
            id="booking-program"
            v-model="program"
            :errors="errors"
            :options="availablePrograms"
            option-label="title"
            track-by="careProgramId"
            label="Studiengang"
            searchable
            required
            mapped
          />
        </validation-provider>
      </e-section>
      <e-section>
        <validation-provider
          v-if="availableCurricula"
          v-slot="{ errors }"
          name="Curriculum"
          rules="required"
          slim
        >
          <e-multiselect
            id="booking-curriculum"
            v-model="curriculum"
            :errors="errors"
            :disabled="!availableCurricula.length"
            :options="availableCurricula"
            option-label="name"
            track-by="id"
            label="Curriculumsversion"
            searchable
            required
            mapped
          />
        </validation-provider>
      </e-section>
      <e-section>
        <validation-provider v-slot="{ errors }">
          <e-radio-group
            v-model="semesterStart"
            :options="semesterOptions"
            :errors="errors"
            rules="required"
            label="Start des Studiengangswechsels"
            name="semester-start"
            track-by="key"
            required
          />
        </validation-provider>
      </e-section>
    </template>

    <template #submit-button-label>Speichern</template>
  </process-modal-form>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { EMultiselect, ERadioGroup } from '@careerpartner/nitro';
import ESection from '@/components/shared/ESection/index';
import Loader from '@/components/shared/Loader';

import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import { Actions as AppActions } from '@/store/modules/app/types';
import sortHelper from '@/mixins/sortHelper';
import dateHelper from '@/mixins/dateHelper';

export default {
  name: 'ProgramChangeModal',
  components: {
    ProcessModalForm,
    ValidationProvider,
    EMultiselect,
    ERadioGroup,
    ESection,
    Loader,
  },
  mixins: [sortHelper, dateHelper],

  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  emits: ['program-change-requested-successfully'],
  data() {
    return {
      loading: false,
      error: '',
      waitingForResponse: false,
      ready: false,
      userParameters: {},
      program: null,
      curriculum: null,
      availablePrograms: [],
      availableCurricula: [],
      semesterStart: 'NEXT_SEMESTER',
      semesterOptions: [
        // TODO: uncomment when feature is implemented
        // { key: 'NOW', label: 'Sofort' },
        { key: 'NEXT_SEMESTER', label: 'zum nächsten Semester' },
      ],
    };
  },
  watch: {
    program() {
      this.loadAvailableCurricula();
    },
  },

  async beforeMount() {
    await this.loadAvailablePrograms();
  },

  methods: {
    async loadAvailablePrograms() {
      this.loading = true;

      try {
        const { data: programs } = await ApiServiceWrapper.get(
          API_ENDPOINTS.GET_AVAILABLE_PROGRAM_CHANGE_PROGRAMS
        );

        this.availablePrograms = programs.filter(
          (program) => program.careProgramId !== this.booking.program.careProgramId
        );
      } catch (e) {
        this.error = getApiError(e);
      }
      this.loading = false;
    },
    async loadAvailableCurricula() {
      const requestUrl = API_ENDPOINTS.PROGRAM_AVAILABLE_CURRICULA.replace(
        ':careProgramId',
        this.program
      );

      try {
        const { data: availableCurricula } = await ApiServiceWrapper.get(requestUrl);
        this.availableCurricula = this.sortCurricula(availableCurricula);
      } catch (e) {
        this.error = getApiError(e);
      }
    },

    async submitProgramChangeRequest() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.CREATE_PROGRAM_CHANGE_REQUEST.replace(
        ':bookingId',
        this.$route.params.bookingId
      );

      const payload = {
        startDate: this.getStartOfNextSemester(this.booking.matriculation.startDate),
        newCareProgramId: this.program,
        newCareCohortId: this.curriculum,
      };

      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Studiengangwechsel war erfolgreich.',
        });

        this.$emit('program-change-requested-successfully');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
